import { basePost } from "@/js/services/baseService";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        // 訂單資訊
        list: [],
        // 開啟的訂單id
        id: 0,
        // 芳療師清單
        aromatherapistList: [],
        // 當前選取的芳療師
        selectedAroma: 0,
    },
    getters: {},
    mutations: {
        update(state, params) {
            const { key, value } = params;
            state[key] = value;
        },
        delListById(state, id) {
            let idx = 0;
            const list = state.list;

            for (let i = 0; i < list.length; i++) {
                if (list[i].id === id) {
                    idx = i;
                    break;
                }
            }

            state.list.splice(idx, 1);
        },
    },
    actions: {
        //取得本日預約列表
        async getReservationList({ commit, state }, params) {
            try {
                const { id, store_id } = params;
                const adjustDate = moment().add(-8, 'h');
                const start_date = adjustDate.format("YYYY-MM-DD 08:00:00");
                const end_date = adjustDate.add(1, 'd').format("YYYY-MM-DD 07:59:59");

                const res = await basePost("/api_reserve/reserve_list", {
                    store_id,
                    start_date,
                    end_date,
                    member_id: JSON.stringify(id ? [id] : []),
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    commit("update", {
                        key: "list",
                        value: data.res_data.reseve_list,
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },
        // 取得芳療師名單
        async getAromatherapistList({ commit, state, dispatch }, params) {
            try {
                const { store_id } = params;
                const res = await basePost("/api_reserve/member_list", {
                    store_id,
                });

                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    if (!data.res_data.length) return;

                    commit("update", {
                        key: "aromatherapistList",
                        value: data.res_data,
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },
        // 取消預約
        async cancelReservation({ commit }, params) {
            try {
                const { reserve_id, store_id } = params;
                const res = await basePost("/api_reserve/reserve_cancel", {
                    store_id,
                    reserve_id,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    commit("delListById", reserve_id);
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
