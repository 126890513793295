import { basePost } from '@/js/services/baseService';

export default {
    namespaced: true,
    state: {
        html: '',
    },
    mutations: {
        updateHtml(state, value) {
            state.html = value;
        },
    },
    actions: {
        async getInvoiceHtml({ commit }, id) {
            const res = await basePost('/api_order/print_pos', { id });
            const { status, data } = res;
            if (status === 200) {
                commit('updateHtml', data.res_data);
            }
        },
        async getTicketHtml({ commit }, id) {
            const res = await basePost('/api_ticket/print_pos_ticket', { id });
            const { status, data } = res;
            if (status === 200) {
                commit('updateHtml', data.res_data);
            }
        },
        async getMerchandiseHtml({ commit }, id) {
            const res = await basePost('/api_merchandise/print_pos_merchandise', { id });
            const { status, data } = res;
            if (status === 200) {
                commit('updateHtml', data.res_data);
            }
            return res
        },
        async getServiceDetailsHtml({ commit }, id) {
            const res = await basePost('/api_order/customer_service_details', { id });
            const { status, data } = res;
            if (status === 200) {
                commit('updateHtml', data);
            }
        },
    },
};
