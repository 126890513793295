<template>
    <div class="position-relative">
        <input
            type="text"
            class="input-blur"
            ref="inputRef"
            @blur="onInputBlur"
        />
        <div
            class="input-group mb-3"
            :class="[
                { 'select-money': dataValue === '現金' },
                { 'select-coupon': dataValue === '用劵' },
            ]"
        >
            <div class="input-group-prepend">
                <div class="input-group-text p-0">
                    <select
                        :name="selectName"
                        @change="handleSelect"
                        :value="selectedVal"
                    >
                        <option selected value="0">請選擇</option>
                        <option
                            v-for="option in options"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </select>

                    <div
                        class="aselect"
                        :data-value="dataValue"
                        :data-list="options"
                        :name="selectName"
                        @click="toggle()"
                    >
                        <div class="selector">
                            <div class="label">
                                <span>{{
                                    dataValue ? dataValue : "請選擇"
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <transition appear name="fade">
                        <div v-show="visible && !disabled">
                            <ul class="sty-scrollbar">
                                <li @click="select(0)">請選擇</li>
                                <li
                                    :class="{
                                        current: item.name === dataValue,
                                    }"
                                    v-for="item in options"
                                    :key="item.id"
                                    @click="select(item.name)"
                                >
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>
                    </transition>
                </div>
            </div>
            <input
                type="text"
                class="form-control"
                :class="inputClass"
                :placeholder="placeholder"
                :name="inputName"
                @input="handleInput"
                :value="inputVal"
                :disabled="disabled"
                @keydown.enter.prevent
                @focus="txtInputFocus"
                @blur="txtInputBlur"
            />
        </div>
        <div class="error-msg" v-show="selectErrorMessage || inputErrorMessage">
            {{ selectErrorMessage || inputErrorMessage }}
        </div>
    </div>
</template>

<script>
import { useField } from "vee-validate";
import { ref, toRef, watch, inject  } from "vue";

export default {
    name: "BaseSelectInput",
    emits: ["update:selectedVal", "update:inputVal", 'selectChange','txtInputBlur'],
    props: {
        couponClass: {
            type: String,
            default: "",
        },
        moneyClass: {
            type: String,
            default: "",
        },
        inputClass: {
            type: String,
            default: "",
        },
        selectedValue: {
            type: String,
            default: "",
        },
        inputValue: {
            type: String,
            default: "",
        },

        selectName: {
            type: String,
            required: true,
        },
        inputName: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const selectName = toRef(props, 'selectName');
        const inputName = toRef(props, 'inputName');
        const { selectedValue, inputValue, options} = props;

        let visible = ref(false);
        let dataValue = ref();
        const inputRef = ref();

        const toggle = () => {
            visible.value = !visible.value;

            if (visible.value) {
                inputRef.value.focus();
            }
        };

        const select = (option) => {
            dataValue.value = option;
            visible.value = false;
        };

        const onInputBlur = () => {
            setTimeout(() => {
                visible.value = false;
            }, 200);
        };

        // validate
        const {
            errorMessage: selectErrorMessage,
            value: selectedVal,
            handleChange: handleChangeSelect,
        } = useField(selectName, undefined, {
            initialValue: selectedValue,
        });
        const update = (val) => {
            handleChangeSelect(val);
            emit("update:selectedVal", val);
            emit('selectChange', val);
        };
        const handleSelect = (e) => {
            const val = e.target.value;

            update(val);
        };
        if (options) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === selectedVal.value) {
                    dataValue.value = options[i].name;
                    break;
                }
            }
        }
        watch(dataValue, (val) => {
            let id;

            for (let i = 0; i < options.length; i++) {
                if (options[i].name === val) {
                    id = options[i].id;
                    break;
                }
            }

            update(id);
        });

        // select值變化時，也需要顯示對應的值
        watch(selectedVal, (val) => {
            if(!val) return;

            const {name} = options.find(option => option.id === val);
            dataValue.value = name;
        })

        const {
            errorMessage: inputErrorMessage,
            value: inputVal,
            handleChange: handleChangeInput,
        } = useField(inputName, undefined, {
            initialValue: inputValue,
        });
        const handleInput = (e) => {
            const val = e.target.value;

            handleChangeInput(val);
            emit("update:inputVal", val);
        };

        const isTxtFocus = ref(false)
        const txtInputFocus = () => {
            isTxtFocus.value = true;
            resetBarcodeScanner();
        }

        const txtInputBlur = (e) => {
            isTxtFocus.value = false;
            emit("txtInputBlur", e);
        }

        // 掃碼機輸入的時候刪除最後一個字
        const { isBarcodeScanner, resetBarcodeScanner  } = inject('barcodeScanner')
        const isString = (val) => typeof val === 'string';

        watch(isBarcodeScanner, (val) => {
            if(selectedVal.value == 2) return;
            if(val && isTxtFocus.value) {
                let newValue = inputVal.value;
                if(newValue && isString(newValue)) {
                    newValue = newValue.slice(0, -1);
                    handleChangeInput(newValue);
                    emit("update:inputVal", newValue);
                }
            }
        })
      
    
        return {
            selectErrorMessage,
            inputErrorMessage,
            selectedVal,
            inputVal,
            visible,
            dataValue,
            toggle,
            select,
            onInputBlur,
            inputRef,
            handleSelect,
            handleInput,
            txtInputFocus,
            txtInputBlur,
            isTxtFocus,
            isBarcodeScanner
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/grid";
@import "~@/css/mixins";

.form {
    .input-group {
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 11px;
        height: 53px;
        @include phone {
            height: 45px;
        }
        .input-group-text {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border: none;
            border-radius: 11px 0px 0px 11px;
            padding: 15px;
            position: relative;
            font-weight: bold;
            @include phone {
                padding: 13px;
            }
            &:after {
                content: "";
                position: absolute;
                border-right: 1px solid #eee;
                top: 10px;
                bottom: 10px;
                right: 0;
                z-index: 1;
            }
        }
        .form-control {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box !important;
            border: none;
            border-radius: 0px 11px 11px 0px;
            padding: 14px;
            font: normal normal 600 16px/22px Open Sans;
            @include phone {
                padding: 13px;
            }
        }
    }
    .select-coupon {
        .aselect {
            border-radius: 11px 0px 0px 11px;
            padding-right: 43px;
            background: url("~@/assets/tgd/lightbox/box1-arrow1.svg") no-repeat
                    80%,
                transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
            .label {
                color: #0f2a68 !important;
            }
        }
        .form-control {
            color: #0f2a68;
        }
    }

    .select-money {
        .aselect {
            border-radius: 11px 0px 0px 11px;
            padding-right: 43px;
            background: url("~@/assets/tgd/lightbox/box1-arrow2.svg") no-repeat
                    80%,
                transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
            .label {
                color: #a4641a !important;
            }
        }
        .form-control {
            color: #a4641a;
        }
    }
    .aselect {
        border-radius: 11px 0px 0px 11px;
        padding-right: 43px !important;

        background: url("~@/assets/tgd/lightbox/box1-arrow1.svg") no-repeat 85%,
            transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
        padding: 15px;
        font: normal normal 600 16px/22px Open Sans;
        appearance: none;
        filter: contrast(100%);
        transition: all 0.3s;
        cursor: pointer;

        .selector {
            overflow: hidden;

            .expanded {
                transform: rotateZ(180deg) translateY(2px);
            }
            .label {
                display: block;
                font: normal normal bold 16px/22px Open Sans;
                letter-spacing: 0px;
                color: #000;
                white-space: nowrap;
            }
        }
    }
    ul {
        width: 100%;
        list-style-type: none;
        position: absolute;
        top: 120%;
        left: 0;
        z-index: 1;
        padding: 11px;
        background: #191919 0% 0% no-repeat padding-box;
        box-shadow: 0px 10px 8px #00000054;
        border: 1px solid #ffffff;
        border-radius: 5px;
        opacity: 0.95;
        overflow-x: hidden;
    }
    li {
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 9px 5px;
        overflow: hidden;
        border-radius: 5px;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
            background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
        }
        &:nth-child(1) {
            &:hover {
                background: unset;
            }
        }
        .current {
            background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
        }
    }

    .hidden {
        visibility: hidden;
    }
    .visible {
        visibility: visible;
        li {
            cursor: pointer;
        }
    }

    .input-blur,
    select {
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }
}
</style>
