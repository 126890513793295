<template>
    <div class="base-datepicker" :class="class">
        <div class="prepend" v-if="label">
            <p class="label">{{ label }}</p>
        </div>
        <div class="content">
            <Datepicker
                :timePicker="timePicker"
                v-model="date"
                :startTime="initDate"
                :enableTimePicker="timePicker"
                :format="getFormatDate"
                hideInputIcon
            />
        </div>
        <div class="error-msg" v-show="errorMessage">
            {{ errorMessage }}
        </div>
    </div>

    <input type="text" :name="name" :value="value" />
</template>

<script>
import { reactive, ref, watch } from "vue";
import { useField } from "vee-validate";
import moment from "moment";

export default {
    name: "BaseDatepicker",
    emits: ["update:selectVal", "selectChange"],
    props: {
        label: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            required: true,
        },
        format: {
            type: String,
            default: "YYYY-MM-DD",
        },
        timePicker: {
            type: Boolean,
            default: false,
        },
        initVal: {
            type: Date,
            default: new Date(),
        },
        class: {
            type: String,
            default: "",
        },
    },
    setup(props, context) {
        const { emit } = context;
        const { name, format, timePicker, initVal } = props;
        const date = timePicker
            ? ref({
                  hours: moment(initVal).hours(),
                  minutes: moment(initVal).minutes(),
              })
            : ref(new Date(initVal));
        const initDate = timePicker
            ? ref({
                  hours: moment(initVal).hours(),
                  minutes: moment(initVal).minutes(),
              })
            : ref(new Date(initVal));
        const getFormatDate = (date) => {
            return moment(date).format(format);
        };
        const { value, errorMessage, handleChange } = useField(
            name,
            undefined,
            {
                initialValue: value,
            }
        );

        const handleSelector = (inputVal) => {
            emit("selectChange", inputVal);
        };

        watch(
            date,
            (val) => {
                const inputVal = val ? moment(val).format(format) : "";

                handleChange(inputVal);
                handleSelector(inputVal);
            },
            { immediate: true }
        );

        watch(value, (val) => {
            if (!val) return;

            if (timePicker) {
                const splitTime = val.split(":");
                date.value.hours = splitTime[0];
                date.value.minutes = splitTime[1];
                return;
            }

            date.value = val;
        });

        return { date, initDate, value, errorMessage, getFormatDate };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.base-datepicker {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 11px;
    border: 1px solid rgb(255, 255, 255);
    box-shadow: -3px -4px 8px 0 rgba(255, 255, 255, 0.61),
        0px 3px 6px rgba(0, 0, 0, 0.29);
    background: linear-gradient(
        180deg,
        rgb(236, 236, 236),
        rgb(249, 249, 249),
        rgb(255, 255, 255)
    );
    position: relative;
    height: 53px;

    .prepend {
        padding: 16px 13px 16px 16px;
        position: relative;

        &::after {
            content: "";
            display: inline-block;
            @include size(1px, 20px);
            background-color: rgba(214, 214, 214, 1);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .label {
        font-size: 16px;
        font-weight: 600;
        word-break: keep-all;
        color: rgba(0, 0, 0, 1);
    }

    :deep(.dp__pointer) {
        padding: 0 14px !important;
        font-size: 16px;
        background: transparent;
        color: rgb(0, 0, 0);
    }
}

// 消費紀錄日期下拉
.input-wrap {
    .input-group {
        background: transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
        height: 53px;
        width: 350px;
        box-shadow: unset !important;
        margin: 0 !important;

        @include pad_pro {
            width: 300px;
            height: 45px;
        }
        .select-wrap {
            display: flex;
            align-items: center;
            height: 53px;
            .base-datepicker {
                border: unset;
                background: unset;
                box-shadow: unset;
                margin: 0;
                background: no-repeat
                        url("~@/assets/tgd/reserve/select-arrow.svg") right 17px
                        top 50%,
                    transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                border: none;
                width: 138px;
                height: 100%;
                font: normal normal 600 16px/22px Open Sans;
                letter-spacing: -0.16px;
                color: #000000;
                :deep(.dp__pointer) {
                    padding: 0 17px !important;
                }
            }

            .radius-left {
                border-radius: 11px 0 0 11px;
            }
            .radius-right {
                border-radius: 0 11px 11px 0;
            }
        }
    }
}

.input-height {
    height: 63px;
}

input {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    z-index: -1;
}

.custom-select {
    cursor: pointer;
    color: green;
    font-weight: bold;
}

.error-msg {
    position: absolute;
    left: 0;
    top: calc(100% + 5px);
}
</style>
