<template>
    <div class="customer">
        <AromaSideMenu />
        <div class="top">
            <AromaLogo />
        </div>
        <div class="index-container">
            <div class="title d-none d-xl-block">出勤紀錄</div>
        </div>

        <div class="wrap">
            <div class="calendar-controller">
                <div class="controller-wrapper">
                    <button
                        type="button"
                        class="btn-controller"
                        @click="reduceNum(dateSelect.year)"
                    >
                        <i class="icon icon-arrow_left"></i>
                    </button>
                    <span class="txt">{{ dateSelect.year }}</span>
                    <button
                        type="button"
                        class="btn-controller"
                        @click="addNum(dateSelect.year)"
                    >
                        <i class="icon icon-arrow_right"></i>
                    </button>
                </div>

                <div class="controller-wrapper">
                    <button
                        type="button"
                        class="btn-controller"
                        @click="reduceNum(dateSelect.month)"
                    >
                        <i class="icon icon-arrow_left"></i>
                    </button>
                    <span class="txt">{{ dateSelect.month }}月</span>
                    <button
                        type="button"
                        class="btn-controller"
                        @click="addNum(dateSelect.month)"
                    >
                        <i class="icon icon-arrow_right"></i>
                    </button>
                </div>
            </div>
            <div class="row reserve-container">
                <div class="reserve-wrap">
                    <div class="order-list-wrap">
                        <div class="order-title">出勤紀錄</div>

                        <div class="order-list-header">
                            <div class="row">
                                <div class="col-2">日期</div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -1.5%"
                                >
                                    排定上班
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -4%"
                                >
                                    排定下班
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -6.5%"
                                >
                                    實際上班
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -9%"
                                >
                                    實際下班
                                </div>
                                <div
                                    class="
                                        col-2
                                        d-flex
                                        flex-column
                                        position-relative
                                    "
                                    style="left: -11.5%"
                                >
                                    正常班數
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="
                                        col-2
                                        d-flex
                                        flex-column
                                        position-relative
                                    "
                                    style="left: -11.5%"
                                >
                                    加班班數
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -16%"
                                >
                                    老點數
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -21%"
                                >
                                    補班數
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -25%"
                                >
                                    課程一
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -29%"
                                >
                                    課程二
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -11%"
                                >
                                    註記
                                </div>
                            </div>
                        </div>
                        <div class="order-list-header">
                            <div class="row">
                                <div class="col-10"></div>
                                <div
                                    class="
                                        col-2
                                        d-flex
                                        flex-column
                                        position-relative
                                    "
                                    style="left: -11.5%"
                                >
                                    {{ recordSum.pingbanSum }}
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    class="
                                        col-2
                                        d-flex
                                        flex-column
                                        position-relative
                                    "
                                    style="left: -11.5%"
                                >
                                    {{ recordSum.overtimeSum }}
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -16%"
                                >
                                    {{ recordSum.oldPointAmoutSum }}
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -21%"
                                >
                                    {{ recordSum.makeUpSum }}
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -25%"
                                >
                                    {{ recordSum.classOneSum }}
                                </div>
                                <div
                                    class="col-2 position-relative"
                                    style="left: -29%"
                                >
                                    {{ recordSum.classTwoSum }}
                                </div>
                                <div class="col-2"></div>
                            </div>
                        </div>
                        <div class="list">
                            <transition appear name="fade" v-if="isListRequest">
                                <NoData v-show="!rosterRecord.data.length" />
                            </transition>
                            <transition-group appear name="fade" v-if="isListRequest">
                                <template
                                    v-for="(item, index) in rosterRecord.data"
                                    :key="index"
                                    v-show="rosterRecord.data.length"
                                >
                                    <div class="order-list">
                                        <div class="row">
                                            <div
                                                class="
                                                    col-2
                                                    item-color
                                                    position-relative
                                                "
                                                style="left: 6px"
                                            >
                                                {{ item.days }}
                                                <span class="date-color ps-3"
                                                    >({{ listWeek(item.week) }})</span
                                                >
                                            </div>
                                            <div
                                                class="col-2 position-relative"
                                                style="left: -1.5%"
                                            >
                                                {{ item.scheduled_start_time }}
                                            </div>
                                            <div
                                                class="col-2 position-relative"
                                                style="left: -4%"
                                            >
                                                {{ item.scheduled_end_time }}
                                            </div>

                                            <div
                                                class="
                                                    col-2
                                                    money-color
                                                    position-relative
                                                "
                                                style="left: -6.5%"
                                            >
                                                {{ item.actual_start_time ? item.actual_start_time : '-' }}
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    money-color
                                                    position-relative
                                                "
                                                style="left: -9%"
                                            >
                                                {{ item.actual_end_time ? item.actual_end_time : '-' }}
                                            </div>
                                            <div
                                                class="col-2 blue position-relative"
                                                style="left: -11.5%"
                                            >
                                                {{
                                                    item.pingban
                                                        ? item.pingban
                                                        : "-"
                                                }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div
                                                class="col-2 gold position-relative"
                                                style="left: -11.5%"
                                            >
                                                {{
                                                    item.overtime
                                                        ? item.overtime
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="col-2 gold position-relative"
                                                style="left: -16%"
                                            >
                                                {{
                                                    item.old_point_amout
                                                        ? item.old_point_amout
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="col-2 red position-relative"
                                                style="left: -21%"
                                            >
                                                {{
                                                    item.make_up
                                                        ? item.make_up
                                                        : "-"
                                                }}
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    class-color
                                                    position-relative
                                                "
                                                style="left: -25%"
                                            >
                                                {{
                                                    item.class_one ? item.class_one : "-"
                                                }}
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    class-color
                                                    position-relative
                                                "
                                                style="left: -29%"
                                            >
                                                {{
                                                    item.class_two ? item.class_two : "-"
                                                }}
                                            </div>
                                            <div
                                                class="
                                                    col-2
                                                    text-center
                                                    blue
                                                    position-relative
                                                "
                                                style="left: -11%"
                                            >
                                                {{ item.note }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </transition-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, computed, nextTick, watch} from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";

import AromaLogo from "@/components/includes/AromaLogo.vue";
import AromaSideMenu from "@/components/includes/AromaSideMenu.vue";
import NoData from "@/components/includes/NoData.vue";

export default {
    name: "AttendanceRecord",
    components: {
        AromaLogo,
        AromaSideMenu,
        NoData
    },
    setup() {
        const { state } = useStore();

        const isListRequest = ref(false);

        // 出勤紀錄
        let rosterRecord = reactive({ data: [] });

        const recordSum = reactive({
            pingbanSum: computed(()=> { 
                return rosterRecord.data.reduce((prev, curr)=> {
                    return prev + curr.pingban
                }, 0)
            }),
            overtimeSum: computed(()=> { 
                return rosterRecord.data.reduce((prev, curr)=> {
                    return prev + curr.overtime
                }, 0)
            }),
            oldPointAmoutSum: computed(()=> { 
                return rosterRecord.data.reduce((prev, curr)=> {
                    return prev + curr.old_point_amout
                }, 0)
            }),
            makeUpSum: computed(()=> { 
                return rosterRecord.data.reduce((prev, curr)=> {
                    return prev + curr.make_up
                }, 0)
            }),
            classOneSum: computed(()=> { 
                return rosterRecord.data.reduce((prev, curr)=> {
                    return prev + curr.class_one
                }, 0)
            }),
            classTwoSum: computed(()=> { 
                return rosterRecord.data.reduce((prev, curr)=> {
                    return prev + curr.class_two
                }, 0)
            })
        })


        // 日期
        const dateSelect = reactive({
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
        });

        // 星期
        const listWeek = (week) => {
            switch (week) {
                case '0':
                    return '日'
                case '1':
                    return '一'
                case '2':
                    return '二'
                case '3':
                    return '三'
                case '4':
                    return '四'
                case '5':
                    return '五'
                case '6':
                    return '六'
            }
        }

        const reduceNum = (num) => {
            if (num > 1) {
                if (
                    num.toString().length === 1 ||
                    num.toString().length === 2
                ) {
                    dateSelect.month = num - 1;
                } else {
                    dateSelect.year = num - 1;
                }
            } else if (num === 1) {
                dateSelect.year = dateSelect.year - 1;
                dateSelect.month = 12;
            }
            getRosterRecord();
        };
        const addNum = (num) => {
            if (num.toString().length === 1 || num.toString().length === 2) {
                if (num < 12) {
                    dateSelect.month = num + 1;
                } else if (num === 12) {
                    dateSelect.year = dateSelect.year + 1;
                    dateSelect.month = 1;
                }
            } else {
                dateSelect.year = num + 1;
            }
            getRosterRecord();
        };

        // 取得出勤紀錄列表
        const getRosterRecord = async () => {
            try {
                const res = await basePost("/api_roster/roster_list", {
                    store_id: state.auth.store_id,
                    year: dateSelect.year,
                    month: dateSelect.month,
                    member_id: state.auth.member_id,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    rosterRecord.data = res_data.member_list[0].days;
                }
            } catch (err) {
                console.error(err);
            }
        };
        getRosterRecord();

        watch(rosterRecord, () => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        return { rosterRecord, dateSelect, reduceNum, addNum, listWeek, isListRequest ,recordSum };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
        }
    }
    .wrap {
        position: relative;
        z-index: 1;
        @include pad {
            position: relative;
            top: 50px;
        }
        @include phone {
            top: 70px;
        }
        .calendar-controller {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -55px;
            margin-bottom: 26px;
            font-size: 16px;
            font-weight: 600;

            .controller-wrapper {
                display: flex;
                align-items: center;
                padding: 0 10px;
            }

            .btn-controller {
                border: none;
                background-color: transparent;
                cursor: pointer;
            }

            .txt {
                padding: 0 20px;
            }
        }
        .reserve-container {
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;
            @include pad_pro_lg {
                overflow: overlay;
                justify-content: start;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                .reserve-title {
                    position: relative;
                    top: -40px;
                    margin-bottom: 20px;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #0f2a68;
                    @include pad_pro {
                        margin: 15px 0;
                        margin-top: 20px;
                    }
                }
                .money-color {
                    color: #a4641a;
                }
                .item-color {
                    color: #4a4a4a;
                }
                .date-color {
                    color: #a2a2a2;
                }
                .class-color {
                    color: #7d7d7d;
                }
                .blue {
                    color: #0f2a68;
                }
                .gold {
                    color: #d5a240;
                }
                .red {
                    color: #d67675;
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1425px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: none;
                }
                .list {
                    height: 700px;
                    overflow-y: auto;
                    padding: 0px 30px;
                    margin: 0 30px;
                    font: normal normal bold 16px/22px Open Sans;
                    &::-webkit-scrollbar-track {
                        background-color: #d6d6d6;
                        border-radius: 2px;
                    }
                    &::-webkit-scrollbar {
                        width: 7px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #707070;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 9px;
                    }
                    @include pad_pro_lg {
                        padding: 0 17px 0 0;
                    }
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 70px 0;
                padding-bottom: 60px;
                font: normal normal 600 16px/22px Open Sans;
                line-height: 21px;
                @include pad_pro_lg {
                    padding-bottom: 40px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                }
                .order-title {
                    color: #a4641a;
                    font-size: 17px;
                    font-weight: bold;
                    position: relative;
                    padding-left: 15px;
                    top: -40px;
                    left: 60px;
                    display: none;
                    &:before {
                        position: absolute;
                        content: "";
                        top: -2px;
                        bottom: -2px;
                        left: 0;
                        border: 3px solid #a4641a;
                    }
                    a {
                        text-decoration: underline !important;
                        font: normal normal bold 17px/27px Microsoft JhengHei;
                        color: #a4641a;
                    }
                    @include pad_pro_lg {
                        left: 30px;
                    }
                    @include pad_pro {
                        display: block;
                    }
                }
                .col-2 {
                    text-align: center;
                }
                .order-list-header {
                    position: relative;
                    top: -15px;
                    display: flex;
                    line-height: 19px;
                    margin: 0 67px 0 60px;
                    .row {
                        flex: 1;
                        align-items: self-start;
                        .col-2 {
                            display: flex;
                            align-content: center;
                            justify-content: center;
                        }
                    }
                    @include pad_pro_lg {
                        padding: 0;
                        margin: 0 55px 0 32px;
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    padding: 9px 0;
                    align-items: center;
                    transition: all 0.3s;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    &:hover {
                        background: #d6d6d6 !important;
                    }
                    &:nth-child(odd) {
                        background: rgba(255, 255, 255, 0.2);
                    }
                    &:nth-child(even) {
                        background: rgba(238, 238, 238, 0.2);
                    }
                }
            }
        }
    }
}
</style>
