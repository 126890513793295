<template>
    <div class="position-relative textarea-wrap">
        <slot></slot>
        <textarea
            :class="class"
            :value="textareaValue"
            :placeholder="placeholder"
            :name="name"
            @input="handleChange"
            :disabled="disabled"
        ></textarea>
    </div>
    <span class="error-msg" v-if="errorMessage">{{ errorMessage }}</span>
</template>

<script>
import { useField } from "vee-validate";
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        class: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const { value, name } = props;

        const {
            value: textareaValue,
            errorMessage,
            handleBlur,
            handleChange,
            meta,
        } = useField(name, undefined, {
            initialValue: value,
        });

        return {
            handleChange,
            handleBlur,
            errorMessage,
            textareaValue,
            meta,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/mixins";
@import "~@/css/grid";
textarea {
    width: 100%;
    padding: 15px 30px;
    background: transparent;
    border: none;
    border-radius: 11px;
    font: normal normal bold 18px/24px Microsoft JhengHei;
    letter-spacing: 0px;
    color: #000000;
    resize: none;
    box-shadow: none !important;
    &::-webkit-scrollbar-track {
        background-color: #d6d6d6;
        border-radius: 2px;
    }
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: #707070;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 9px;
    }

    &.custom-textarea {
        height: auto;
        min-height: 73px;
        padding: 5px 10px;
        border: 1px solid rgba(162, 162, 162, 1);
        border-radius: 5px;
        box-shadow: unset !important;
        font-size: 16px;
        background: transparent;
        color: rgba(0, 0, 0, 1);
    }
}
.textarea-wrap {
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box;
    border-radius: 11px;
    @include hover(90%);
    border: 1px solid #ffffff;
    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029 ;
}
</style>
