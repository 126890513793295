import { computed, reactive } from "vue";

const memberDataList = [
    "member_type",
    "token",
    "member_name",
    "store_id",
    "lounge_id",
    "is_logged_in",
    "store_name",
    "member_id",
    "hierarchy",
    "rank_name"
];
export default {
    namespaced: true,
    state: () => ({
        member_type: '',
        token: '',
        member_name: '',
        store_id: '',
        lounge_id: '',
        is_logged_in: false,
        store_name: '',
        member_id: '',
        hierarchy: '',
        rank_name: ''
    }),
    getters: {
        // 已登入
        is_logged_in(state, getters) {
            return state.is_logged_in;
        },
        // 詳細
        authInfo(state, getters) {
            return reactive({
                token: computed(() => state.token),
                member_type: computed(() => state.member_type),
                member_name: computed(() => state.member_name),
                store_id: computed(() => state.store_id),
                lounge_id: computed(() => state.lounge_id),
                is_logged_in: computed(() => state.is_logged_in),
                store_name: computed(() => state.store_name),
                member_id: computed(() => state.member_id),
                hierarchy: computed(() => state.hierarchy),
                rank_name: computed(() => state.rank_name),
            });
        },
    },
    mutations: {
        updateMemberData(state, data) {
            // console.log('auth data: ', data);

            memberDataList.forEach((name) => {
                if (data[name] !== undefined) {
                    state[name] = data[name];
                    localStorage.setItem(name, data[name]);
                }
            });
        },

    },
    actions: {
        login({ commit }, data) {
            commit("updateMemberData", data);
        },
        logout({ commit }, data) {
            var emptyObj = {};

            memberDataList.forEach((name) => {
                localStorage.removeItem(name);
            });

            commit("updateMemberData", emptyObj);
        },
        init({ commit }) {
            var dataObj = {};

            memberDataList.forEach((name) => {
                let data = localStorage.getItem(name);
                if (data) {
                    try {
                        data = JSON.parse(data);
                        dataObj[name] = data;
                    } catch (err) {
                        console.log("auth init err", err);
                    }
                }
            });

            commit("updateMemberData", dataObj);
        },
    },
};
