<template>
    <div class="customer-detail">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/customer"> 客戶管理 </router-link>>
                <router-link :to="{ name: 'ConsumptionRecord' }"> 消費紀錄 </router-link>
                > 明細
            </div>
        </div>
        <div class="wrap">
            <transition appear name="fade" v-if="isOrderRequest">
                <NoData v-show="!Object.keys(orderData.data)" />
            </transition>
            <transition appear name="fade" v-if="isOrderRequest">
                <div v-show="Object.keys(orderData.data)">
                    <div class="row detail-container">
                        <div class="reserve-wrap">
                            <div class="order-list-wrap">
                                <div class="order-list-header">
                                    <div class="row g-0 " >
                                        <div class="col-2">服務時間</div>
                                        <div class="col-2">服務類型</div>
                                        <div class="col-2 text-center">使用券</div>
                                        <div class="col-2 text-center">服務項目</div>
                                        <div class="col-2 text-center">老點</div>
                                        <div class="col-2 text-center">金額</div>
                                    </div>
                                </div>
                                <div class="order-list num-font">
                                    <div class="row g-0 "
                                        v-for="(item, index) in orderData.data.service"
                                        :key="index"
                                    >
                                        <div class="col-2 gray num-font">{{ orderDate }} {{orderData.data.start_time}} - {{orderData.data.end_time}}</div>
                                        <div class="col-2">一般服務</div>
                                        <div class="col-2 text-center gray-blue num-font" v-if="item.type === 2">-</div>
                                        <div class="col-2 text-center gray-blue num-font" v-else>{{ item.txt }}</div>
                                        <div class="col-2 text-center num-font">{{ serviceObj[item.service_id] && serviceObj[item.service_id].name }}</div>
                                        <div class="col-2 text-center">
                                            <div class="circle-old mx-auto">
                                                <img
                                                    v-if="item.is_old === 1"
                                                    src="@/assets/tgd/customer/is_old.svg"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div class="col-2 text-center red num-font" v-if="item.type === 2">${{ item.txt }}</div>
                                        <div class="col-2 text-center red num-font" v-else>$0</div>
                                    </div>
                                    <div class="row g-0 "
                                        v-for="(item, index) in orderData.data.external"
                                        :key="index"
                                    >
                                        <template
                                            v-for="(val, i) in item.list"
                                            :key="index"
                                        >
                                            <template v-if="i===0">
                                                <div class="col-2 gray num-font">{{ orderDate }} {{orderData.data.start_time}} - {{orderData.data.end_time}}</div>
                                                <div class="col-2">外部券({{ externalObj[item.external_id] && externalObj[item.external_id].name }})</div>
                                                <div class="col-2 text-center gray-blue num-font">{{ val.txt }}</div>
                                            </template>
                                            <template v-else>
                                                <div class="col-6"></div>
                                            </template>
                                            <div class="col-2 text-center num-font">{{ serviceObj[val.service_id] && serviceObj[val.service_id].name }}</div>
                                            <div class="col-2 text-center">
                                                <div class="circle-old mx-auto">
                                                    <img
                                                        v-if="val.is_old === 1"
                                                        src="@/assets/tgd/customer/is_old.svg"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div class="col-2 text-center red num-font">{{ i === 0 ?  '$0' : '' }}</div>
                                        </template>
                                    </div>
                                </div>
                                <div class="total">
                                    <div class="row border-bottom">
                                        <div class="col-5 text-start">小計</div>
                                        <div class="col-7 text-end num-font">
                                            ${{ orderData.data.amount }}
                                        </div>
                                    </div>
                                    <div class="row" v-if="orderData.data.special_id > 0">
                                        <div class="col-5 text-start">特約結帳: {{ specialObj[orderData.data.special_id] && specialObj[orderData.data.special_id].name }}</div>
                                        <div class="col-7 text-end num-font">
                                            -${{ Math.abs(orderData.data.special_price) }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-5 text-start">增補金額</div>
                                        <div class="col-7 text-end num-font">
                                            ${{ orderData.data.supplement }}
                                        </div>
                                    </div>
                                    <div class="row  border-top border-bottom red">
                                        <div class="col-5 text-start">應付金額</div>
                                        <div class="col-7 text-end num-font">
                                            ${{ finalNum }}
                                        </div>
                                    </div>

                                    <div class="row blue">
                                        <div class="col-5 text-start">
                                            其他付款方式: {{ paymentObj[orderData.data.payment_method] && paymentObj[orderData.data.payment_method].name }} </div>
                                        <div class="col-7 text-end num-font">
                                            ${{ orderData.data.balance }}
                                        </div>
                                    </div>

                                    <div class="row blue">
                                        <div class="col-5 text-start">現金付款</div>
                                        <div class="col-7 text-end num-font">
                                            ${{ orderData.data.cash_payment  }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";

import { ref, reactive, watch , computed } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";

export default {
    name: "ConsumptionRecordDetail",
    components: {
        Logo,
        NoData,
    },
    setup() {
        const { state } = useStore();
        const isOrderRequest = ref(false);

        // 訂單資料
        let orderData = reactive({
            data: {},
            info:{}
        });

        // 客戶 ID
        const orderId = JSON.parse(localStorage.getItem("orderId"));
        //  訂單時間
        const orderDate = JSON.parse(localStorage.getItem("orderDate"));


        //取得消費紀錄
        const getOrderDetail= async (orderId) => {
            try {
                const res = await basePost(
                    "/api_order/order_details",
                    {
                        order_id: orderId,
                        store_id: state.auth.store_id,
                    }
                );
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    const { answer_list, ...args } = data.res_data
                    orderData.data = answer_list
                    orderData.info = { ...args }
                    await countMoney();
                }
            } catch (err) {
                console.error(err);
            }
        };
        getOrderDetail(orderId);

        // 外部券清單
        let externalList = reactive({ data: [] });
        // 取得外部券清單
        const getExternalList= async () => {
            const res = await basePost("/api_external/external_list", {
                store_id: state.auth.store_id,
            });
            const { status, data } = res;

            if (status === 200 && data.res_code === 1) {
                const { res_data } = data;
                externalList.data = res_data;
            }
        };
        getExternalList()



        watch(orderData, () => {
            if(!isOrderRequest.value) {
                isOrderRequest.value = true;
            }
        })


        // 計算應付金額
        const finalNum = ref(0)
        function countMoney() {
            const {
                amount,
                special_price,
                supplement
            } = orderData.data

            let fnum = amount - Math.abs(special_price);

            if (supplement < 0) {
                fnum = fnum - Math.abs(supplement);
            } else {
                fnum = fnum + Math.abs(supplement);
            }

            finalNum.value = fnum;
        };


        function arrayToObject(arr, key) {
            if(!arr) return {}
            return [...arr].reduce((prev, curr) => {
                return {
                        ...prev,
                        [curr[key]]: curr
                    }
            }, {})
        }

        const serviceObj = computed(()=>{
            return arrayToObject(orderData.info.serviceAry, 'id');
        })
        const specialObj = computed(()=>{
            return arrayToObject(orderData.info.specialAry, 'id');
        })
        const paymentObj = computed(()=>{
            return arrayToObject(orderData.info.payment_method, 'key');
        })
        const externalObj = computed(()=>{
            return arrayToObject(externalList.data, 'id');
        })

        return {
            isOrderRequest,
            orderData,
            serviceObj,
            specialObj,
            paymentObj,
            externalObj,
            finalNum,
            orderDate
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer-detail {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap {
        max-width: 1600px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: 1400px) {
            padding: 0;
        }
        .detail-container {
            justify-content: center;

            @media (max-width: 1400px) {
                overflow: overlay;
                margin: 0;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .money-color {
                    color: #a4641a;
                }
                .pay-type-color {
                    color: #707070;
                }
                .sum-color {
                    color: #c80036;
                }
                .blue {
                    color: #0f2a68;
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1600px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 70px 55px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro_lg {
                    padding: 0 20px;
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                    position: relative;
                    top: 60px;
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    padding: 0 20px;
                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 25px;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    .row {
                        padding: 7px 0;
                        flex: 1;
                        align-items: center;
                        &:nth-child(2n - 1) {
                            background: rgba(214, 214, 214, .2);
                        }
                        .col-2 {
                            padding: 10px 15px;
                        }
                    }
                    &:hover {
                        filter: contrast(90%);
                    }
                }
            }
        }
        .circle-old {
            width: 20px;
            height: 20px;
            background: #D6D6D6;
            border-radius: 50%
        }
    }
    .total {
        width: 450px;
        margin: 0 0 0 auto;
        padding: 20px;
        .row {
            padding: 10px 0;
        }
        .border-top {
            border-top: 1px dashed #c9c9c9 !important;
            padding-top: 20px;
            margin-top: 10px;
        }
        .border-bottom {
            border-bottom: 1px dashed #c9c9c9 !important;
            padding-bottom: 20px;
            margin-bottom: 10px;
        }
        .blue {
            color: #0F2A68;
        }
    }
    .red {
        color:#C80036;
    }
    .gray {
        color: rgba(0, 0, 0, .5);
    }
    .gray-blue {
        color:#767F98
    }
}
</style>
