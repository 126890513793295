<template>
    <div class="index">
        <AromaSideMenu />
        <div class="top">
            <AromaLogo />
        </div>
        <div class="index-container">
            <div class="title">基本資訊</div>
            <div class="hierarchy">聘階 : {{ hierarchy }} <span></span> 職級 : {{ rank_name }}</div>
        </div>
        <transition appear name="fade" v-if="requestState.memberList">
            <NoData v-if="
                requestState.announcementList &&
                !(memberList.data.ranking_list && memberList.data.ranking_list.length) && 
                !announcementList.data.length
            " />
            <div class="container" v-else>
                <div class="row">
                    <div class="col-xl-8">
                        <div class="order-list-wrap">
                            <div class="title">本月競賽排名</div>
                            <div class="wrap">
                                <div class="row order-list" v-for="(item, index) in memberList.data.ranking_list" :key="index">
                                    <div class="col-md-2">
                                        <div :class="{'tag tag-yellow': index === 0, 'tag tag-gray': index === 1, 'tag tag-brown': index === 2, 'common': index > 2}">{{ index + 1 }}</div>
                                    </div>
                                    <div class="col-md-2">{{ item.store_name }}</div>
                                    <div class="col-md-6">{{ item.member_name }}</div>
                                    <div class="col-md-2 red">{{ item.competitio_point }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <div class="staff-show-wrap">
                            <div class="staff-num row">
                                <div class="col-4">
                                    <p>第 <span class="should">{{ memberList.data.ranking }}</span> 名</p>
                                    <p class="should">本月目前排名</p>
                                </div>
                                <div class="col-4">
                                    <p><span class="actually">{{ memberList.data.competitio_point }}</span> 點</p>
                                    <p class="actually">本月競賽點數</p>
                                </div>
                                <div class="col-4">
                                    <p><span class="num">{{ memberList.data.performance_point }}</span> 點</p>
                                    <p class="num">本月業績點數</p>
                                </div>
                            </div>
                        </div>
                        <div class="news-wrap"
                            v-show="requestState.announcementList"
                        >
                            <div class="news-header">
                                <div class="news-title">
                                    <img
                                        src="@/assets/tgd/index/notice.svg"
                                        alt=""
                                    />
                                    <span>公告</span>
                                </div>
                                <div class="more">
                                    <router-link to="/aroma_post">more</router-link>
                                </div>
                            </div>
                            <div class="news-body">
                                <template
                                    v-for="(
                                        announcement, index
                                    ) in announcementList.data"
                                    :key="index"
                                >
                                    <p class="post-title">
                                        {{ announcement.start_date }}-{{
                                            announcement.end_date
                                        }}{{ announcement.title }}
                                    </p>
                                    <div class="post-text" v-html="announcement.content"></div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import AromaLogo from "@/components/includes/AromaLogo.vue";
import AromaSideMenu from "@/components/includes/AromaSideMenu.vue";
import NoData from "@/components/includes/NoData.vue";

import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";

export default {
    name: "AromaIndex",
    components: {
        AromaLogo,
        AromaSideMenu,
        NoData
    },
    setup(){
        const { state } = useStore();
  
        const hierarchy = computed(()=>{
            return state.auth.hierarchy || ''
        })
        const rank_name = computed(()=>{
            return state.auth.rank_name || ''
        })

        const isListRequest = ref(false);

        // 芳療師基本資訊
        const memberList = reactive({ data: [] });
        // 公告資訊
        let announcementList = reactive({ data: [] });

        const requestState = reactive({
            memberList: false,
            announcementList: false
        })

        // 取得芳療師基本資訊
        const getMemberList = async () => {
            try {
                const res = await basePost("/api_competition/competition_list", {
                    store_id: state.auth.store_id,
                    member_id: state.auth.member_id,
                });
                const { status, data } = res;
                requestState.memberList = true;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    memberList.data = res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };

        getMemberList();

        // 取得公告資訊
        const getPost = async () => {
            try {
                const res = await basePost("/api_common/announcement_list", {
                    store_id: state.auth.store_id,
                });
                const { status, data } = res;
                requestState.announcementList = true;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    announcementList.data = res_data.list;
                }
            } catch (err) {
                console.error(err);
            }
        };

        getPost();

        return {
            memberList, 
            announcementList, 
            requestState,
            hierarchy,
            rank_name
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.index {
    .index-container {
        position: relative;
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            @include bt_phone_pad {
                display: none;
            }
        }
        .hierarchy {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font: normal normal bold 20px/27px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #a4641a;
            span {
                margin: 0px 5px;
            }
            @include bt_phone_pad {
                position: relative;
                text-align: center;
                transform: none;
                top: auto;
                left: auto;
            }
        }
    }
    .col-xl-8{
        padding-right: 0;
        @include bt_phone_pad {
            padding-right: 15px;
        }
    }
    .container {
        max-width: 1550px;
        margin: 0 auto;
        .order-list-wrap,
        .news-wrap,
        .staff-show-wrap {
            margin-bottom: 18.5px;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #ffffff;
            border-radius: 15px;
            filter: contrast(100%);
            transition: all 0.3s;
            &:hover {
                filter: contrast(105%);
            }
        }
        .order-list-wrap {
            background: transparent
                linear-gradient(122deg, #ffffff 0%, #d9d9d9 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            padding-top: 25px;
            padding-bottom: 80px;
            font: normal normal bold 16px/21px Microsoft JhengHei;

            @include pad_pro {
                margin-bottom: 15px;
            }
            .title {
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #a4641a;
                padding: 0 30px;
                margin: 0 30px;
                padding-bottom: 20px;
            }
            .wrap {
                height: 669.5px;
                overflow-y: auto;
                padding: 0 30px;
                margin: 0 30px;
                &::-webkit-scrollbar-track {
                    background-color: #d6d6d6;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #707070;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 9px;
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    height: 75px;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    &:hover {
                        filter: contrast(90%);
                    }
                    @include bt_phone_pad {
                        height: unset;
                        padding: 10px;
                        text-align: center;
                        .col-md-2 {
                            padding: 10px 0;
                        }
                    }
                    .tag {
                        height: 45px;
                        display: flex;
                        justify-content: center;
                        font: normal normal bold 26px/36px Open Sans;
                        letter-spacing: 0px;
                        color: #ffffff;
                    }
                    .tag-yellow {
                        background: no-repeat center/contain
                            url("~@/assets/tgd/aroma/yellow-tag.svg");
                    }
                    .tag-gray {
                        background: no-repeat center/contain
                            url("~@/assets/tgd/aroma/gray-tag.svg");
                    }
                    .tag-brown {
                        background: no-repeat center/contain
                            url("~@/assets/tgd/aroma/brown-tag.svg");
                    }
                    .red {
                        color: #d67675;
                        font: normal normal bold 20px/27px Open Sans;
                    }
                    .common {
                        font: normal normal bold 20px/27px Open Sans;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .news-wrap,
        .staff-show-wrap {
            background: transparent
                linear-gradient(148deg, #ffffff 0%, #e5e5e5 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
        }
        .news-wrap {
            .news-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px;
                padding-top: 32px;
                padding-bottom: 15px;
                padding-right: 35px;
                position: relative;
                &:after {
                    position: absolute;
                    content: "";
                    border-bottom: 1px solid #a4641a;
                    right: 30px;
                    left: 20px;
                    bottom: 0;
                }
                .news-title {
                    font-size: 20px;
                    color: #a4641a;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    span {
                        line-height: 22px;
                        padding-left: 10px;
                    }
                }
                .more {
                    a {
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        color: #a4641a;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(150%);
                        }
                    }
                }
            }
            .news-body {
                height: 506.5px;

                overflow-y: auto;
                padding: 0px 15px;
                padding-bottom: 10px;
                margin: 10px;
                .post-title {
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0.8px;
                    color: #b77f1a;
                    margin-top: 15px;
                    margin-bottom: 5px;
                    line-height: 21px;
                }
                .post-text {
                    line-height: 23px;
                }
                &::-webkit-scrollbar-track {
                    background-color: #d6d6d6;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #707070;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 9px;
                }
            }
        }
        .staff-show-wrap {
            height: 208px;
            display: flex;
            align-items: center;
            justify-content: center;
            .staff-num {
                width: 100%;
                color: #4a4a4a;
                .col-4 {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    &:after {
                        position: absolute;
                        content: "";
                        top: 0px;
                        bottom: 0px;
                        right: 0;
                        border-right: 1px solid rgba(112, 112, 112, .2);
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                    p {
                        margin-bottom: 5px;
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        @include phone {
                            font-size: 14px;
                        }
                        span {
                            font: normal normal bold 28px/38px Open Sans;
                            font-size: 24px;
                        }
                    }
                    .should {
                        color: #b77f1a;
                    }
                    .actually {
                        color: #a4641a;
                    }
                    .num {
                        color: #d67675;
                    }
                }
            }
        }
    }
}
</style>
