import { basePost } from "@/js/services/baseService";
import moment from "moment";

const getStartDate = (type, date) => {
    let startDate;

    if (type === "daily") {
        startDate = moment(date).format("YYYY-MM-DD") + " 08:00:00";
    } else {
        startDate = moment(date).format("YYYY-MM-01 08:00:00");
    }

    return startDate;
};

const getEndDate = (type, date) => {
    let endDate;

    if (type === "daily") {
        endDate = moment(date).add(1, 'd').format("YYYY-MM-DD 07:59:59");
    } else {
        var thisMonth = moment(moment(date).format("YYYY-MM"));
        endDate = thisMonth.add(1, 'M').format("YYYY-MM-01 07:59:59");
    }

    return endDate;
};

const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const isBetween = (start, end, now) => {
    const format = 'HH:mm:ss';
    const start_time = moment(start, format).add(-8, 'hours').format(format);
    const end_time  = moment(end, format).add(-8, 'hours').format(format);
    const now_time  = moment(now, format).add(-8, 'hours').format(format);
    return moment(
        moment(now_time, format),
        format
    ).isBetween(
        moment(start_time, format),
        moment(end_time, format),
        undefined,
        '[)'
    );
}
const isOverlap = (start, end, nowStart, nowEnd) => {
    return isBetween(start, end, nowStart) || isBetween(start, end, nowEnd)
}


export default {
    namespaced: true,
    state: {
        // 訂單資訊
        listOfDaily: [], // 日曆預約表所需(篩選)
        listOfDailyOriginal: [], // 日曆預約表所需(原始)
        listOfMonthly: [], // 月曆預約表所需
        listOfLeave: [], // 月曆預約請假資訊
        listOfReservation: [], // 月曆預約資訊
        selectedDate: null, // 當前選擇的日期
        onWorkAroma: [], // 上班人員
        onHolidayAroma: [], // 休假人員
        lightboxInfo: null, // 預約燈箱所需資訊
        WORK_TIME_ZONE: [
            "09:00",
            "09:30",
            "10:00",
            "10:30",
            "11:00",
            "11:30",
            "12:00",
            "12:30",
            "13:00",
            "13:30",
            "14:00",
            "14:30",
            "15:00",
            "15:30",
            "16:00",
            "16:30",
            "17:00",
            "17:30",
            "18:00",
            "18:30",
            "19:00",
            "19:30",
            "20:00",
            "20:30",
            "21:00",
            "21:30",
            "22:00",
            "22:30",
            "23:00",
            "23:30",
            "00:00",
            "00:30",
            "01:00",
            "01:30",
            "02:00",
            "02:30",
            "03:00",
            "03:30",
            "04:00",
            "04:30",
            "05:00"
        ],
        selectedEventId: "", // 當前點選的預約id(編輯燈箱用)
        selectedReserveStatus: 0, // 當前點選的預約狀態(編輯燈箱用)
        viewStatus: "daily", // 預約表顯示狀態(daily:日曆, monthly:月曆)
        monthlySelectedMemberId: "", // 月曆預約表選擇的芳療師id
        currentSelectedMemberIds: [], // 日曆預約表當前選擇的芳療師id
    },
    getters: {
        getYear(state) {
            return Number(moment(state.selectedDate).format("YYYY"));
        },
        getMonth(state) {
            return Number(moment(state.selectedDate).format("MM"));
        },
        getDate(state) {
            return Number(moment(state.selectedDate).format("DD"));
        },
        getDay(state) {
            const dayArr = ["日", "一", "二", "三", "四", "五", "六"];
            let day = moment(state.selectedDate).days();

            return "星期" + dayArr[day];
        },
        getAromaList(state) {
            return state.lightboxInfo.memberAry;
        },
        getDemand(state) {
            return state.lightboxInfo.answer_list &&
            state.lightboxInfo.answer_list.demand;
        },
        getRoomList(state) {
            return state.lightboxInfo.roomAry;
        },
        getServiceList(state) {
            return state.lightboxInfo.serviceAry;
        },
        getCustomerData(state) {
            return state.lightboxInfo ? state.lightboxInfo.customer_data : {};
        },
        getOnWorkAromaColor(state) {
            return state.onWorkAroma.reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr.member_id]: curr.color
                }
            }, {});
        },
        getMonthlyEventList(state, getters) {
            let arr = state.listOfMonthly.map((item) => {
                const { id, customer_name, date, time, reserve_amount, member } = item;
                const member_id = member[0] &&  member[0].member_id || 0;
                return {
                    id,
                    title: "",
                    start: date,
                    classNames: ["reservation"],
                    time,
                    customerName: customer_name,
                    reservedNum: reserve_amount,
                    borderColor: "rgba(255, 255, 255, 1)",
                    backgroundColor: getters.getOnWorkAromaColor[member_id]
                };
            });
            arr = arr.concat(
                state.listOfLeave.map((item) => {
                    const { date, leaveStr } = item;
                    let color = "";

                    switch (leaveStr) {
                        case "例假日":
                            color = "rgba(0, 121, 242, 1)";
                            break;
                        case "榮譽假":
                            color = "rgba(183, 127, 26, 1)";
                            break;
                        case "休假日":
                            color = "rgba(5, 170, 102, 1)";
                            break;
                        case "請假":
                            color = "rgba(255, 65, 111, 1)";
                            break;
                        case "半天榮譽假":
                            color = "rgba(109, 69, 69, 1)";
                            break;
                    }

                    return {
                        id: date,
                        title: leaveStr,
                        start: moment(date).format("YYYY-MM-DD"),
                        classNames: ["holiday"],
                        backgroundColor: color,
                        borderColor: color,
                    };
                })
            );
            return arr;
        },
        getIsShowDaily(state) {
            return state.viewStatus === "daily" ? true : false;
        },
        getSortList(state) {
            const sortList = state.listOfDaily.sort(function (a, b) {
                return moment(moment(a.start_time, 'HH:mm:ss').add(-8, 'hours').format('HH:mm:ss'), 'HH:mm:ss') -
                moment(moment(b.start_time, 'HH:mm:ss').add(-8, 'hours').format('HH:mm:ss'), 'HH:mm:ss')
            });
            const timeList = [];
            sortList.forEach(item => {
                if(!timeList.length) {
                    timeList[0] = [item];
                    return;
                }

                let isPush = false
                for (let i = 0; i < timeList.length; i++) {
                    let hasOverlap = false;
                    for (let j = 0; j < timeList[i].length; j++) {
                        let obj = timeList[i][j];
                        if(isOverlap(obj.start_time, obj.end_time, item.start_time, item.end_time)){
                            hasOverlap = true;
                        }
                    }
                    if(!hasOverlap && !isPush) {
                        timeList[i].push(item);
                        isPush = true;
                    }
                }
                if(!isPush) {
                    timeList.push([item]);
                }
            })

            return timeList.map(item =>{
                return item.map(val => val.id)
            })
        }
    },
    mutations: {
        update(state, params) {
            const setVal = (obj) => {
                const { key, value } = obj;
                state[key] = value;
            };

            if (Array.isArray(params)) {
                params.forEach((item) => {
                    setVal(item);
                });
                return;
            }

            setVal(params);
        },
        fallback2Today() {
            this.commit("reservationTable/update", {
                key: "selectedDate",
                value: moment().format("YYYY-MM-DD"),
            });
        },
        delListById(state, id) {
            let idx = 0;
            const list = state.list;

            for (let i = 0; i < list.length; i++) {
                if (list[i].id === id) {
                    idx = i;
                    break;
                }
            }

            state.list.splice(idx, 1);
        },
        filterListOfDaily(state) {
            // 篩選如果是符合搜尋以及勾選的
            function filterArr() {
                const memberArr = state.currentSelectedMemberIds;
                let filterArr = [];

                for (let i = 0; i < memberArr.length; i++) {
                    let isAppear = state.onWorkAroma.find(
                        (aroma) =>
                            aroma.member_id == memberArr[i] && aroma.isAppear
                    );

                    if (isAppear) {
                        filterArr.push(memberArr[i]);
                    }

                    let isAppearHoliday = state.onHolidayAroma.find(
                        (aroma) =>
                            aroma.member_id == memberArr[i] && aroma.isAppear
                    );

                    if (isAppearHoliday) {
                        filterArr.push(memberArr[i]);
                    }
                }

                return filterArr;
            }

            const getFilterArr = filterArr();

            const { listOfDailyOriginal, listOfDaily } = state;
            const len = listOfDaily.length;

            const filterList = listOfDailyOriginal.filter((item) => {
                const getId = getFilterArr.find((memberId) => {
                    if (!item.member[0]) return;

                    let hasId = false;

                    for (let i = 0; i < item.member.length; i++) {
                        if (item.member[i].member_id == memberId) {
                            hasId = true;
                            break;
                        }
                    }

                    return hasId;
                });

                if(getId) {
                    return getId;
                }else if(getId === 0) {
                    return true;
                }
            });

            if (len === filterList.length) return;

            state.listOfDaily = filterList;
        },
        resetDemand(state) {
            if(!state.lightboxInfo) return;
            state.lightboxInfo.answer_list.demand = [];
        }
    },
    actions: {
        async getList({ state, commit }, params) {
            const { store_id, member_id, reqType } = params;
            const reqData = {
                store_id,
                member_id:
                    reqType === "daily" ? null : JSON.stringify(member_id),
                start_date: getStartDate(reqType, state.selectedDate),
                end_date: getEndDate(reqType, state.selectedDate),
                type: 1
            };

            try {
                const res = await basePost(
                    "/api_reserve/reserve_list",
                    reqData
                );
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    if (reqType === "daily") {
                        function getListWColor(reserveList, aromaList) {
                            return reserveList.map((item) => {
                                if (item.member.length) {
                                    let findAroma = aromaList.find(
                                        (aroma) =>
                                            aroma.member_id ===
                                            item.member[0].member_id
                                    );

                                    if (!findAroma) {
                                        findAroma = aromaList.find((aroma) => {
                                            for (
                                                let i = 0;
                                                i < item.member.length;
                                                i++
                                            ) {
                                                if (
                                                    aroma.member_id ===
                                                    item.member[i].member_id
                                                ) {
                                                    return true;
                                                }
                                            }
                                        });
                                    }

                                    if (!findAroma) {
                                        item.color = generateRandomColor();
                                    } else {
                                        item.color = findAroma.color;
                                    }

                                    // 每個芳療師要有各自對應的顏色
                                    if(item.member.length) {
                                        item.member.forEach((member) => {
                                            let findAroma = aromaList.find(
                                                (aroma) =>
                                                    aroma.member_id ===
                                                    member.member_id
                                            );

                                            if (!findAroma) {
                                                findAroma = aromaList.find(
                                                    (aroma) =>
                                                        aroma.member_id ===
                                                        member.member_id
                                                );
                                            }

                                            if (!findAroma) {
                                                member.color = generateRandomColor();
                                            } else {
                                                member.color = findAroma.color;
                                            }
                                        });
                                    }
                                }

                                return item;
                            });
                        }

                        const aromaList = state.onWorkAroma;
                        const listWColor = getListWColor(
                            data.res_data.reseve_list,
                            aromaList
                        );

                        commit("update", [
                            {
                                key: "listOfDailyOriginal",
                                value: listWColor,
                            },
                            {
                                key: "listOfDaily",
                                value: listWColor,
                            },
                        ]);

                        commit("filterListOfDaily");

                        // console.log("reserve_list: ", state.listOfDaily);
                    } else {
                        commit("update", {
                            key: "listOfMonthly",
                            value: data.res_data.reseve_list,
                        });

                        commit("update", {
                            key: "listOfLeave",
                            value: data.res_data.roster_list,
                        });

                        // console.log("monthly: ", data.res_data);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getAromaStatus({ commit, getters, state }, params) {
            const { store_id } = params;
            const year = getters.getYear;
            let month = getters.getMonth;
            month = month < 10 ? `0${Number(month)}` : month;
            let date = getters.getDate;
            date = date < 10 ? `0${Number(date)}` : date;

            // 上班人員
            try {
                const res = await basePost(
                    "/api_reserve/should_work_member_list",
                    {
                        store_id,
                        year,
                        month,
                        day: date,
                    }
                );
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    const aromaList = data.res_data.list.map((item) => {
                        item.isAppear = true;
                        return item;
                    });
                    commit("update", {
                        key: "onWorkAroma",
                        value: aromaList,
                    });
                    // console.log("onWorkAroma: ", state.onWorkAroma);
                }
            } catch (err) {
                console.error(err);
            }

            // 休假人員
            try {
                const res = await basePost("/api_reserve/relax_member_list", {
                    store_id,
                    year,
                    month,
                    day: date,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    commit("update", {
                        key: "onHolidayAroma",
                        value: data.res_data.list.map((item) => {
                            item.color = generateRandomColor();
                            item.isAppear = true;
                            return item;
                        }),
                    });
                    // console.log("onHolidayAroma: ", state.onHolidayAroma);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getMonthlyReservedList({ commit, state }, params) {
            const { store_id, year, month } = params;

            const getConvertedList = (list) => {
                let hasToday = false;

                let convertedList = list.map((item) => {
                    const isToday = moment(item.date).isSame(
                        moment().format("YYYY-MM-DD")
                    );

                    if (isToday) {
                        hasToday = true;
                    }

                    return {
                        key: isToday ? "today reserved" : "reserved",
                        highlight: true,
                        dates: new Date(item.date),
                    };
                });

                if (!hasToday) {
                    convertedList.push({
                        key: "today",
                        highlight: true,
                        dates: new Date(),
                    });
                }

                return convertedList;
            };

            try {
                const res = await basePost(
                    "/api_reserve/appointment_reserve_date",
                    {
                        store_id,
                        year,
                        month: month < 10 ? `0${Number(month)}` : month,
                        day: "",
                    }
                );
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    let convertedList = getConvertedList(data.res_data.list);

                    commit("update", {
                        key: "listOfReservation",
                        value: convertedList,
                    });
                    // console.log("listOfReservation: ", state.listOfReservation);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getLightboxInfo({ commit }, params) {
            const { store_id, reserve_id, date, time } = params;
            try {
                const res = await basePost("/api_reserve/reserve_details", {
                    store_id,
                    reserve_id: reserve_id || "0",
                    date,
                    time
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    commit("update", {
                        key: "lightboxInfo",
                        value: data.res_data,
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },
        async initMutliRequest({ state, dispatch }, params) {
            const { store_id, calendarYear, calendarMonth, date } = params;

            try {
                await dispatch("getAromaStatus", {
                    store_id,
                });
                await dispatch("getMonthlyReservedList", {
                    store_id,
                    year: calendarYear,
                    month: calendarMonth,
                });
                await dispatch("getLightboxInfo", {
                    store_id,
                    date,
                    time: "08:00"
                });
                await dispatch("getList", {
                    store_id,
                    member_id: state.onWorkAroma.map((item) => item.member_id),
                    reqType: "daily",
                });
            } catch (err) {
                console.error(err);
            }
        },
        async initSingleRequest({ getters, dispatch }, params) {
            const { store_id, date } = params;

            try {
                await dispatch("getLightboxInfo", {
                    store_id,
                    date,
                    time: "08:00"
                });
                // await dispatch("getList", {
                //     store_id,
                //     member_id: [getters.getAromaList[0].id],
                //     reqType: "monthly",
                // });
            } catch (err) {
                console.error(err);
            }
        },
    },
};
