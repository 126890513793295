<template>
    <div class="stock">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/stock"> 庫存管理 </router-link>
                > 紀錄查看
            </div>
        </div>
        <div class="wrap">
            <BaseSelect
                title=""
                rootClass="stock-selector"
                :isHasOptionAll="true"
                @selectChange="handleSelectChange"
                :options="[
                    {
                        'id': '',
                        'name': '全部'
                    },
                    {
                        'id': '1',
                        'name': '入庫'
                    },
                    {
                        'id': '2',
                        'name': '調貨入庫'
                    },
                    {
                        'id': '3',
                        'name': '其他入庫'
                    },
                    {
                        'id': '4',
                        'name': '領用'
                    },
                    {
                        'id': '5',
                        'name': '調貨出庫'
                    },
                    {
                        'id': '6',
                        'name': '其他出庫'
                    },
                ]"
            />
            <transition appear name="fade" v-if="isListRequest">
                <NoData v-show="!stockRecordList.data.list.length" />
            </transition>
            <transition appear name="fade" v-if="isListRequest">
                <div v-show="stockRecordList.data.list.length">
                    <div class="main-text">
                        {{ stockRecordList.data.commodity_name }}
                        <span>剩餘數量:</span>
                        <span class="num">{{
                            stockRecordList.data.amount
                        }}</span>
                    </div>
                    <div class="row reserve-container">
                        <div class="reserve-wrap">
                            <div class="order-list-wrap">
                                <div class="row order-list-header">
                                    <div
                                        class="
                                            col-2
                                            text-center
                                            position-relative
                                        "
                                        style="left: -20px"
                                    >
                                        序
                                    </div>
                                    <div
                                        class="
                                            col-2
                                            text-center
                                            position-relative
                                        "
                                        style="left: -85px"
                                    >
                                        日期
                                    </div>
                                    <div
                                        class="
                                            col-2
                                            justify-content-start
                                            position-relative
                                        "
                                        style="left: -80px"
                                    >
                                        類型
                                    </div>
                                    <div
                                        class="col-4 position-relative"
                                        style="left: -10px"
                                    >
                                        備註
                                    </div>
                                    <div class="col-2 text-center">數量</div>
                                </div>
                                <div
                                    class="row order-list"
                                    v-for="(
                                        stockRecord, index
                                    ) in stockRecordList.data.list"
                                    :key="index"
                                >
                                    <div
                                        class="
                                            col-2
                                            text-center
                                            gray
                                            position-relative
                                        "
                                        style="left: -20px"
                                    >
                                        {{ index + 1 }}
                                    </div>
                                    <div
                                        class="
                                            col-2
                                            text-center
                                            gray
                                            position-relative
                                        "
                                        style="left: -85px"
                                    >
                                        {{ stockRecord.date }}
                                    </div>
                                    <div
                                        class="
                                            col-2
                                            blue
                                            d-flex
                                            align-items-center
                                            justify-content-start
                                            position-relative
                                        "
                                        style="left: -85px"
                                        v-if="
                                            stockRecord.typeStr == '入庫' ||
                                            stockRecord.typeStr == '其他入庫' ||
                                            stockRecord.typeStr == '調貨入庫'
                                        "
                                    >
                                        <img
                                            src="@/assets/tgd/stock/in.svg"
                                            class="pe-2"
                                            alt=""
                                        />
                                        {{ stockRecord.typeStr }}
                                    </div>
                                    <div
                                        class="
                                            col-2
                                            red
                                            d-flex
                                            align-items-center
                                            justify-content-start
                                            position-relative
                                        "
                                        style="left: -85px"
                                        v-else
                                    >
                                        <img
                                            src="@/assets/tgd/stock/out.svg"
                                            class="pe-2"
                                            alt=""
                                        />
                                        {{ stockRecord.typeStr }}
                                    </div>
                                    <div
                                        class="col-4 position-relative"
                                        style="left: -10px; word-wrap: break-word;"
                                    >
                                        {{ stockRecord.remark }}
                                    </div>
                                    <div
                                        class="col-2 text-center"
                                        :class="{
                                            blue: stockRecord.amount > 0,
                                            red: stockRecord.amount < 0,
                                        }"
                                        style="font-size: 22px"
                                    >
                                        <span v-show="stockRecord.amount > 0"
                                            >+</span
                                        >
                                        {{ stockRecord.amount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="pageInfo.totalPage > 1">
                            <BasePagination
                                :pageInfo="pageInfo"
                                @pageChange="updatePageData"
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";
import { useRoute, useRouter } from "vue-router";

import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import { isLegal } from "@/js/functions";

export default {
    name: "Record",
    components: {
        Logo,
        NoData,
    },
    setup() {
        const route = useRoute();

        const router = useRouter();

        const backPage = () => {
            router.push({
                name: "Stock",
            });
        };

        const { state } = useStore();

         const storeId = computed(() => {
            return state.auth.store_id;
        })

        let pageInfo = reactive({
            totalPage: 1,
            perPage: 5,
        });

        // 庫存 ID
        const commodityId = JSON.parse(localStorage.getItem("commodityId"));

        const isListRequest = ref(false);

        // 庫存紀錄
        let stockRecordList = reactive({ data: [] });

        // 使用狀態選項
        const selectedUseStatus = ref("");

        //取得庫存紀錄列表
        const getStockRecordList = async (num = 1) => {
            try {
                const res = await basePost("/api_commodity/commodity_log_list", {
                    store_id: storeId.value,
                    commodity_id: commodityId,
                    type: selectedUseStatus.value,
                    page: num,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    stockRecordList.data = res_data;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };

        const handleSelectChange = (selectVal) => {
            selectedUseStatus.value = selectVal;
            getStockRecordList();
        };

        const updatePageData = (num) => {
            getStockRecordList(num);
        };

        getStockRecordList();

        watch(() => stockRecordList.data.list, (list) => {
            if(list.length) {
                if (!isListRequest.value) {
                    isListRequest.value = true;
                }
            }
        });
        return {
            updatePageData,
            stockRecordList,
            pageInfo,
            handleSelectChange,
            isListRequest
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.stock {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
        }
    }
    .wrap {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        top: -72px;
        @include pad_pro_lg {
            padding: 0;
        }
        @include pad_air {
            position: relative;
            top: 55px;
        }
        @include phone {
            padding: 20px;
        }
        .input-group {
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 11px;
            height: 53px;
            width: 300px;
            position: absolute;
            right: -5px;
            top: -67px;
            @include phone {
                height: 45px;
                position: relative;
                top: 0;
                right: 10px;
            }
            select {
                flex: 1;
                background: #f9f9f9
                    url("~@/assets/tgd/lightbox/select-arrow-box.svg") no-repeat
                    101%;
                border: none;
                border-radius: 11px;
                padding: 15px;
                font: normal normal 600 16px/22px Open Sans;
                appearance: none;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(130%);
                }
                @include pad_pro_lg {
                    padding: 12px;
                }
            }
        }
        .main-text {
            position: absolute;
            top: 0;
            right: 40%;
            font: normal normal bold 22px/30px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #0f2a68;
            @include pad_air {
                right: 0;
                left: 0;
            }
            @include phone {
                left: 10px;
                top: -50px;
            }
            span {
                font: normal normal bold 16px/21px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #000000;
                padding-left: 20px;
            }
            .num {
                font: normal normal bold 32px/43px Open Sans;
                letter-spacing: 0px;
                color: #0f2a68;
                @include phone {
                    font-size: 27px;
                }
            }
        }
        .reserve-container {
            justify-content: center;
            @include pad_pro_lg {
                overflow: overlay;
                justify-content: start;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;

                .order-list-wrap {
                    margin-bottom: 15px;
                    box-shadow: 0px 34px 77px #00000042;
                    border: 1px solid #ffffff;
                    border-radius: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    width: 1400px;
                    &:hover {
                        filter: contrast(105%);
                    }
                    @include pad_pro_lg {
                        box-shadow: unset;
                    }
                }
                .order-list-wrap {
                    background: transparent
                        linear-gradient(
                            122deg,
                            #ffffff 0%,
                            #d9d9d9 18%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    padding: 75px;
                    padding-bottom: 45px;
                    font: normal normal bold 16px/22px Open Sans;
                    @include pad_pro_lg {
                        padding: 0 20px;
                        padding-bottom: 40px;
                        padding-top: 60px;
                    }
                    @include pad_pro {
                        margin-bottom: 15px;
                    }
                    @include pad {
                        font-size: 14px;
                    }
                    .col-2 {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            padding-right: 5px;
                        }
                    }
                    .blue {
                        color: #007cff;
                    }
                    .red {
                        color: #d94343;
                    }
                    .gray {
                        color: #aaa;
                    }

                    .order-list-header {
                        position: relative;
                        top: -35px;
                        @include pad_pro_lg {
                            top: -25px;
                        }
                    }
                    .order-list {
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #f9f9f9 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        padding: 26px 0;
                        align-items: center;
                        margin-bottom: 15px;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(90%);
                        }
                    }
                }
            }
        }
    }
}

:deep(.stock-selector) {
    max-width: 295px;
    margin-left: auto;
}

</style>
