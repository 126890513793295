import { upperFirst, camelCase } from "lodash";

/**
 * 定義全域可取用的components
 * @param {Function} requireComponent - require.context 導入模組的方法回傳
 * @param {Object} app - Vue實例對象
 */
function covertComp2GlobalUse(requireComponent, app) {
    requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName);
        const replaceFileName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1");
        const splitFileName = replaceFileName.split("/");

        const componentName = upperFirst(
            camelCase(splitFileName[splitFileName.length - 1])
        );

        app.component(
            componentName,
            componentConfig.default || componentConfig
        );
    });
}

/**
 * 物件資料內容轉換成FormData
 * @param {Object} obj - 要被轉換的物件
 * @returns 回傳FormData物件
 */
function objToFormData(obj) {
    var fd = new FormData();

    if (obj && obj.constructor === Object) {
        for (var key in obj) {
            if (
                obj.hasOwnProperty(key) &&
                obj[key] !== undefined &&
                obj[key] !== null
            ) {
                if (obj[key].constructor === Array) {
                    var keyName = key + "[]";
                    // 後端接收陣列
                    obj[key].forEach(function (val, idx) {
                        fd.append(keyName, val);
                    });
                } else {
                    // 其他值
                    fd.append(key, obj[key]);
                }
            }
        }
    }

    return fd;
}

const isLegal = (value) => {
    if (!value) {
        return 0;
    } else {
        return value;
    }
};

//數字格式化
function numberFormat(num, precision, separator) {
    var parts;
    // 判斷是否為數字
    if (!isNaN(parseFloat(num)) && isFinite(num)) {
        num = Number(num);
        // 處理小數點位數
        num = (typeof precision !== 'undefined' ? num.toFixed(precision) : num).toString();
        // 分離數字的小數部分和整數部分
        parts = num.split('.');
        // 整數部分加[separator]分隔, 借用一個著名的正則表達式
        parts[0] = parts[0].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + (separator || ','));

        return parts.join('.');
    }
    return NaN;
}
function print_pos(id) {

    console.log("asdsasda")
    $.ajax({
        url:'/ajax/ajax_common/print_pos',
        data:{id:id},
        dataType: 'json',
        type: 'POST',
        async:false,
        success: function(data) {
            $("#test").append(data);
            window.print(); 


        }
    })
}
export { 
    covertComp2GlobalUse, 
    objToFormData, 
    isLegal,

    // 數值
    numberFormat 
};


