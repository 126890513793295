<template>
    <div class="print-sec" v-html="html"></div>
</template>

<script>
import { computed, watch, nextTick, onUpdated } from "vue";
import { useStore } from "vuex";

export default {
    setup() {
        const { state } = useStore();
        const html = computed(() => state.ticket.html);

        watch(html, (newHtml) => {
            if(newHtml) {
                nextTick(() => {
                    setTimeout(() => {
                        window.print();
                    }, 500);
                });
            }
        })

        return { html };
    },
};
</script>

<style lang="scss" scoped>
.print-sec {
    display: none;
}

@media print {
    .print-sec {
        display: block;
    }
}
</style>
