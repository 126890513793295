import { basePost } from "@/js/services/baseService";
export default {
    namespaced: true,
    state: {
        storeList: []
    },
    getters: {
        selectList(state) {
            return state.storeList.map(item => {
                return { 
                    'id': item.id,
                    'name': item.name
                }
            });
        },
    },
    mutations: {
        updateStoreList(state, list) {
            state["storeList"] = list;
        },
    },
    actions: {
        // 取得店家列表
        async getStoreList({ commit, state, dispatch }, params) {
            try {
                const res = await basePost("/api_store/store_list");
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    commit("updateStoreList", data.res_data.store_list);
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
