<template>
    <div class="input-group mb-3" :class="rootClass">
        <div class="input-group-prepend" v-if="title">
            <span class="input-group-text" :class="titleClass">{{
                title
            }}</span>
        </div>
        <template v-if="!replaceBySlot">
            <input
                :class="inputClass"
                :type="pwdShow ? 'text' : type"
                :name="name"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                v-bind="$attrs"
                @input="handleInput"
                @blur="handleInputBlur"
                :value="inputValue"
                @keydown.enter.prevent
            />
            <a
                href="javascript:;"
                class="eyes"
                @click="handleChangePwdType"
                v-if="name == 'password' || name == 'new_password' || name == 'again_password'"
            >
                <img
                    src="@/assets/tgd/login/look.svg"
                    alt=""
                    v-if="pwdShow"
                />
                <img
                    src="@/assets/tgd/login/unlook.svg"
                    alt=""
                    v-else
                />
            </a>
        </template>
        <div class="form-control p-0" v-else>
            <slot />
        </div>
    </div>
    <div class="error-msg" v-show="errorMessage">
        {{ errorMessage }}
    </div>
</template>

<script>
import { ref, toRef } from "vue";
import { useField } from "vee-validate";

export default {
    name: "BaseInput",
    emits: ["inputBlurred", "update:modelValue"],
    props: {
        rootClass: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
        titleClass: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        inputClass: String,
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "on",
        },
        replaceBySlot: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: String,
            default: "",
        },
        validateOnValueUpdate: {
            type: Boolean,
            default: true,
        }
    },
    setup(props, context) {
        const { emit } = context;
        const name = toRef(props, 'name');
        const { value, replaceBySlot } = props;
        const pwdShow = ref(0);

        if (replaceBySlot) return;

        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
            meta,
        } = useField(name, undefined, {
            initialValue: value,
            validateOnValueUpdate: props.validateOnValueUpdate
        });
        const handleInput = (e) => {
            const val = e.target.value;
            handleChange(val);
            emit("update:modelValue", val);
        };

        const handleInputBlur = () => {
            handleBlur();
            emit("inputBlurred", inputValue);
        };

        const handleChangePwdType = () => {
            pwdShow.value = !pwdShow.value;
        }

        return {
            handleInputBlur,
            errorMessage,
            inputValue,
            meta,
            handleInput,
            pwdShow,
            handleChangePwdType
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/grid";
@import "~@/css/mixins";


  .input-group {
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 11px;
    height: 53px !important;
    position: relative;
    @include phone{
        height: 45px;
    }
    .input-group-text {
      background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box;
      border: none;
      border-radius: 11px 0px 0px 11px;
      padding: 15px;
      position: relative;
      font-weight: bold;
      @include phone{
          padding: 13px;
      }
      &:after {
        content: "";
        position: absolute;
        border-right: 1px solid #eee;
        top: 10px;
        bottom: 10px;
        right: 0;
        z-index: 1;
      }
      .select-coupon {
        border-radius: 11px 0px 0px 11px;
        padding-right: 32px;
        height: 100%;
        color: #0f2a68;
        background: #f9f9f9 url("~@/assets/tgd/lightbox/box1-arrow1.svg")
          no-repeat 90%;
      }

      .select-money {
        border-radius: 11px 0px 0px 11px;
        padding-right: 32px;
        height: 100%;
        color: #a4641a;
        background: #f9f9f9 url("~@/assets/tgd/lightbox/box1-arrow2.svg")
          no-repeat 90%;
      }
    }
    .end-text {
      color: rgba(0, 0, 0, 0.5);
    }
    .form-control {
      background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box;
      border: none;
      border-radius: 0px 11px 11px 0px;
      padding: 14px;
      font: normal normal 600 16px/22px Open Sans;
      @include phone{
          padding: 13px;
      }
    }
    select {
      flex: 1;
      background: #f9f9f9 url("~@/assets/tgd/lightbox/select-arrow-box.svg")
        no-repeat 101%;
      border: none;
      border-radius: 11px;
      padding: 15px;
      font: normal normal 600 16px/22px Open Sans;
      appearance: none;
      filter: contrast(100%);
      transition: all 0.3s;
      @include phone{
          padding: 13px;
      }
      &:hover {
        filter: contrast(130%);
      }
    }
    .custom-rounded {
      border-radius: 0px 11px 11px 0px !important;
    }
    .border-radius {
        border-radius: 11px;
    }

    .money-text {
      color: #a4641a;
    }
  }
  .coupon-text {
      .input-group-text, .form-control{
          color: #0f2a68;
      }

    }

  .input-height{
      height: 63px !important;
      .input-group-text, .form-control{
          padding: 20px;
      }
  }

  .eyes {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

</style>
