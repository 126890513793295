<template>
    <div class="no-print-sec">
        <div class="mask" v-show="isShow" @click="closeMerchandiseLightbox"></div>

        <transition name="slide">
            <div class="coupon-lightbox" v-show="isShow">
                <template v-if="isMerchandiseEmpty">
                    <div class="side-menu-wrap">
                        <form class="side-menu" @submit="handleMerchandise">
                            <div
                                class="side-menu-close"
                                @click="closeMerchandiseLightbox"
                            >
                                <img
                                    src="@/assets/tgd/lightbox/close.svg"
                                    alt=""
                                />
                            </div>
                            <div class="side-menu-top pt-3">
                                <div class="side-menu-item">
                                    <div class="title mb-3">客戶資訊：</div>
                                    <div class="row form">
                                        <div class="col-5">
                                            <BaseInput
                                                title="會員電話"
                                                placeholder="請輸入"
                                                type="tel"
                                                class="form-control"
                                                name="phone"
                                                @blur="checkMember()"
                                            />
                                        </div>
                                        <div class="col-7">
                                            <BaseInput
                                                title="會員名稱"
                                                placeholder="請輸入"
                                                type="text"
                                                class="form-control"
                                                name="name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu-item">
                                    <div class="form">
                                        <div class="col-5 p-0 mb-3">
                                            <button
                                                type="button"
                                                class="btn violation"
                                                @click="
                                                    push({ category_id: 0, merchandise_id: 0, number: 1})
                                                "
                                            >
                                                <img
                                                    src="@/assets/tgd/lightbox/add-button.svg"
                                                    alt=""
                                                />
                                                <span> 新增商品 </span>
                                                <span></span>
                                            </button>
                                        </div>
                                        <template
                                            v-for="(field, idx) in fields"
                                            :key="field.key"
                                        >
                                            <div class="position-relative">
                                                <div class="row g-0">
                                                    <div class="col-4">
                                                         <BaseSelect
                                                            title=""
                                                            :options="categoryList"
                                                            :name="`merchandise[${idx}].category_id`"
                                                            placeholder="選擇分類"
                                                            @selectChange="
                                                                changeCategoryId(idx)
                                                            "
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <BaseSelect
                                                            title=""
                                                            :options="merchandiseList[field.value.category_id]"
                                                            :name="`merchandise[${idx}].merchandise_id`"
                                                            placeholder="選擇商品"
                                                            :disabled="!field.value.category_id"
                                                            :validateOnValueUpdate="false"
                                                        />
                                                    </div>
                                                    <div class="col-2">
                                                        <div
                                                            class="input-group d-inline-block me-2"
                                                        >
                                                            <div class="input-group-prepend counter" >
                                                                <span class="input-group-text py-0">
                                                                    <a
                                                                        href="javascript:;"
                                                                        @click="
                                                                            values.merchandise[idx].number > 1 && changeNumber(values.merchandise[idx], false)
                                                                        "
                                                                    >-</a>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control text-center"
                                                                        readonly
                                                                        v-model="values.merchandise[idx].number"
                                                                    />
                                                                    <a
                                                                        href="javascript:;"
                                                                        @click="changeNumber(values.merchandise[idx], true)"
                                                                    >+</a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 cash">
                                                        $ {{ merchandisePrice(idx) }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="cancel-img"
                                                    @click="remove(idx)"
                                                >
                                                    <a href="javascript:;">
                                                        <img
                                                            src="@/assets/tgd/lightbox/del.svg"
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu-bottom">
                                <div class="side-menu2-item">
                                    <div class="row no-gutters sum">
                                        <div class="col-5">小計</div>
                                        <div class="col-7">
                                            $ {{ numberFormat(coupon_count) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu2-item">
                                    <div class="form">
                                        <div class="row no-gutters">
                                            <div class="col-5">現金付款</div>
                                            <div class="col-7">
                                                <BaseCalcSelectInput
                                                    selectDate=""
                                                    inputClass="custom-radius"
                                                    placeholder="請輸入"
                                                    selectName="calc_select"
                                                    inputName="calc_input"
                                                />
                                            </div>
                                        </div>
                                        <div class="row no-gutters">
                                            <div class="col-5">剩餘款項</div>
                                            <div class="col-7">
                                                <div class="row no-gutters">
                                                    <div class="col-6 px-0 pay-type">
                                                        信用卡
                                                    </div>
                                                    <div class="col-6 px-0 text-end fz-20 last-color">
                                                        ${{
                                                            numberFormat(
                                                                coupon_count -
                                                                values.calc_input
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5"></div>
                                            <div class="col-7">
                                                <BaseInput
                                                    rootClass="authorization-input"
                                                    title="授權碼"
                                                    placeholder="請輸入授權碼"
                                                    type="text"
                                                    class="form-control"
                                                    name="authorization_code"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="side-menu2-item">
                                    <div class="form">
                                        <div class="row no-gutters">
                                            <div class="col-5">統編</div>
                                            <div class="col-7">
                                                <BaseCalcSelectInput
                                                    selectDate=""
                                                    inputClass="custom-radius"
                                                    placeholder="請輸入"
                                                    selectName="select_editor"
                                                    inputName="input_editor"
                                                />
                                            </div>
                                        </div>
                                        <div 
                                            v-if="merchandise.data.store_data && merchandise.data.store_data.is_two"
                                            class="row" 
                                        >
                                            <div class="col-5">選擇要開立發票的公司</div>
                                            <div class="col-7 radio-wrap">
                                                <template
                                                    v-for="store_data in merchandise
                                                        .data
                                                        .store_data
                                                        .invoice_list"
                                                    :key="store_data.id"
                                                >
                                                    <BaseRadio
                                                        type="radio"
                                                        name="invoice_type"
                                                        :label="store_data.uniform_numbers"
                                                        :value="store_data.id"
                                                        v-model="invoice_type"
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                        <div class="row pt-3">
                                            <div class="col-5 ps-0">
                                                <div class="input-group">
                                                    <button
                                                        class="
                                                            btn
                                                            btn-block
                                                            btn-cancel
                                                        "
                                                        @click="
                                                            closeMerchandiseLightbox
                                                        "
                                                    >
                                                        取消
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-7 p-0">
                                                <div class="input-group">
                                                    <button
                                                        class="
                                                            btn
                                                            btn-block
                                                            btn-checkout
                                                        "
                                                        :disabled="isSubmitting"
                                                    >
                                                        <span>總計</span>
                                                        <div class="price">
                                                            <span>NT$</span>
                                                            <strong>{{
                                                                numberFormat(
                                                                    coupon_count -
                                                                    values.calc_input
                                                                )
                                                            }}</strong>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactive, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { useForm, useFieldArray} from "vee-validate";
import * as yup from "yup";
import { basePost } from "@/js/services/baseService";
import { flashMessage } from "@/js/swal";
import { numberFormat } from "@/js/functions";

export default {
    name: "MerchandiseLightbox",
    setup() {
        const { state, dispatch } = useStore();
        const isShow = computed(() => state.global.isMerchandiseLightboxShow);
        const { closeMerchandiseLightbox } = useHandleLightboxOpen();
        const storeId = computed(() => state.auth.store_id);

        // 全部票券加起來的價格
        let coupon_count = ref(0);

        // form驗證設定
        const validationSchema = yup.object({
            phone: yup.string().trim('不可留空').matches(/^09[0-9]{8}$/, '手機格式不正確').required("必填"),
            name: yup.string().required("必填"),
            calc_input: yup.string().required("必填"),
            merchandise: yup.array()
                .of(
                    yup.object().shape({
                        merchandise_id: yup.number().typeError('必填').min(1, "必填"),
                        number: yup.number().typeError('必填').min(1, "必填"),
                    })
                ).strict(),
            authorization_code: yup.string().when("calc_input", (calc_input, schema) => {
                if (coupon_count.value - Number(calc_input) > 0) {
                    return schema.required("必填");
                }
                return schema;
            })
        });

        const { values, handleSubmit, isSubmitting, setValues, resetForm } =
            useForm({
                validateOnMount: false,
                validationSchema,
                initialValues: {
                    invoice_type: 1
                }
            });

        const { remove, push, fields, replace } = useFieldArray("merchandise");
        replace([]);

        const handleMerchandise = handleSubmit(async (values) => {
            const merchandise = values.merchandise.map(item => {
                return {
                    merchandise_id: item.merchandise_id,
                    number: item.number
                }
            })
            try {

                const res = await basePost("/api_merchandise/merchandise_check", {
                    store_id: storeId.value,
                    merchandise: JSON.stringify(merchandise),
                    cash_payment: values.calc_input,
                    payment_method: values.pay_type || 'credit',
                    uniform_numbers: values.input_editor,
                    customer_phone: values.phone,
                    customer_name: values.name,
                    authorization_code: values.authorization_code,
                    invoice_type: values.invoice_type
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    flashMessage("購買成功");
                    closeMerchandiseLightbox();
                    // 訂單是否開立發票 0不開立 1開立
                    if(data.res_data.is_issue_invoice == 0) return;
                    dispatch('ticket/getMerchandiseHtml', data.res_data.order_id);
                } else {
                    flashMessage(data.res_content, "error");
                }
            } catch (err) {}
        });

        //取得商品清單
        let merchandise = reactive({ data: {} });
        const getMerchandise = async () => {
            try {
                const res = await basePost("/api_merchandise/merchandise_list", {
                    store_id: storeId.value,
                });
                const { status, data } = res;
                merchandise.data = res.data.res_data;
            } catch (err) {
                console.error(err);
            }

            values.calc_input = 0;
        };

        const categoryList =  computed(() => merchandise.data.merchandise_list);
        const merchandiseList =  computed(() => {
            return merchandise.data.merchandise_list.reduce((prev, curr)=> {
                return {...prev, [curr.id]: curr.erchandise_list}
            },{})
        })
        const merchandiseListObj =  computed(() => {
            return merchandise.data.merchandise_list.reduce((prev, curr)=> {
                return {
                    ...prev,
                    [curr.id]: curr.erchandise_list.reduce((prev, curr)=> {
                        return {
                            ...prev,
                            [curr.id]: curr
                        }
                    },{})
                }
            },{})
        });

        const isMerchandiseEmpty = computed(() => {
            return true;
        });

        watch(isShow, () => {
            if (isShow.value) {
                resetForm();
                replace([]);
                getMerchandise();
            }
        });


        watch([
                () => values.calc_input,
                () => values.merchandise,
            ],
            () => {
                countResult();
            },
            {
                deep: true
            }
        );

        const countResult = async () => {
            let total_amount = 0
            if(values.merchandise) {
                total_amount += values.merchandise
                    .map((item) => {
                        return merchandiseObjPrice(item.category_id, item.merchandise_id, item.number);
                    })
                    .reduce((total, now) => {
                        return total + now;
                    }, 0);
            }
            coupon_count.value = total_amount;
        };

        // 取得客戶相關資訊
        const checkMember = async () => {
            const res = await basePost("/api_customer/customer_data", {
                store_id: storeId.value,
                customer_phone: values.phone,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                values.name = data.res_data.name;
            }
        };

        function changeNumber(item, bool) {
            if(bool){
                item.number ++;
            }else{
                item.number --;
            }
        }

        function merchandisePrice(idx) {
            const item = values.merchandise[idx];
            return numberFormat(merchandiseObjPrice(item.category_id, item.merchandise_id, item.number));
        }

        function merchandiseObjPrice(category_id, merchandise_id, number) {
            if(category_id === 0 || merchandise_id === 0 || number === 0) return 0;
            return merchandiseListObj.value[category_id][merchandise_id].price * number
        }

        function changeCategoryId(idx){
            const item = values.merchandise[idx];
            item.merchandise_id = 0;
        }

        return {
            isShow,
            closeMerchandiseLightbox,
            values,
            handleMerchandise,
            isSubmitting,
            merchandise,
            remove,
            push,
            fields,
            isMerchandiseEmpty,
            checkMember,
            coupon_count,
            changeNumber,
            categoryList,
            merchandiseList,
            merchandisePrice,
            changeCategoryId,
            numberFormat
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 800px;
$tab-width: 120px;
$tab-menu-width: 895px;
.coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0px;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;

    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;
            height: 100%;
            width: 100%;
            background: #e1e1e1;
        }
        .side-menu-top {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            .side-menu-item {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                }
                label {
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"] + span {
                        display: inline-block;
                        padding-left: 35px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/check.svg")
                            no-repeat;
                        user-select: none;
                        font: normal normal 600 20px/27px Open Sans;
                        letter-spacing: 0px;
                        color: #986d46;
                    }
                    input[type="checkbox"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/checked.svg")
                            no-repeat;
                    }
                }
                .col-2,
                .col-4 {
                    padding: 0 5px;
                }
                .col-5 {
                    flex: 0 0 45%;
                    max-width: 45%;
                }
                .col-7 {
                    flex: 0 0 55%;
                    max-width: 55%;
                }
                .col-5 {
                    padding-right: 0;
                    @include phone {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
                .col-7 {
                    @include phone {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        padding: 0;
                    }
                }

                .cancel-img {
                    position: absolute;
                    right: -40px;
                    top: 50%;
                    transform: translate(0%, -75%);
                    img {
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(130%);
                        }
                    }
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        align-items: center;
                        font: normal normal bold 20px/22px Open Sans;
                        letter-spacing: 0px;
                        color: #a4641a;
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #f9f9f9 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        .counter {
                            flex: 20%;
                            @include hover(90%);
                            .input-group-text {
                                font: normal normal bold 22px/30px Open Sans;
                                letter-spacing: 0px;
                                color: #a4641a;
                                a {
                                    font: normal normal bold 24px/30px Open Sans;
                                    letter-spacing: 0px;
                                    color: #a4641a;
                                    @include hover(200%);
                                }
                            }
                        }
                        .input-group-text {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px;
                            padding: 13px;
                            position: relative;
                            font-weight: bold;
                            width: 100%;
                            justify-content: space-between;
                            &:after {
                                display: none;
                            }
                        }

                        .form-control {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px !important;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        .money-text {
                            height: 100%;
                            text-align: right;
                            font: normal normal bold 19px/26px Open Sans;
                            letter-spacing: 0px;
                            color: #d94343;
                        }
                        .coupon-text {
                            color: #a4641a;
                            @include phone {
                                text-align: center;
                            }
                        }
                        .gray-text {
                            color: #707070;
                            @include phone {
                                text-align: center;
                            }
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9
                                url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                                no-repeat 100.5%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                    .btn {
                        width: 100%;
                        border-radius: 11px;
                        padding: 15px;
                        background: transparent
                            linear-gradient(
                                282deg,
                                #a4641a 0%,
                                #b57831 36%,
                                #a4641a 88%,
                                #ebc496 100%,
                            );
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #ffffff85;
                        &.violation {
                            background: transparent
                                linear-gradient(
                                    282deg,
                                    #481e00 0%,
                                    #9c5828 36%,
                                    #cf9064 88%,
                                    #8f5126 100%,
                                );
                        }
                    }
                    .cash {
                        color: #C80036;
                        text-align: right;
                        font-size: 20px;
                        height: 53px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        font-weight: bold;
                    }
                }
            }
        }
        .side-menu-bottom {
            background: #e1e1e1;
            .side-menu2-item {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #ccc;
                @include phone {
                    padding: 20px;
                }
                label {
                    margin-bottom: 10px !important;
                    margin-right: 10px !important;
                    input[type="radio"] {
                        display: none;
                    }
                    input[type="radio"] + span {
                        display: inline-block;
                        padding-left: 38px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/radio-none.svg")
                            no-repeat;
                        user-select: none;
                    }
                    input[type="radio"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/radio-checked.svg")
                            no-repeat;
                        color: #a4641a;
                    }
                    span {
                        font-size: 16px;
                    }
                }
                .sum {
                    letter-spacing: 0px;
                    color: #000000;
                    .col-5 {
                        font: normal normal bold 18px/24px Microsoft JhengHei;
                    }
                    .col-7 {
                        font: normal normal bold 20px/27px Open Sans;
                        text-align: right;
                    }
                }
                .col-5 {
                    flex: 0 0 48%;
                    max-width: 48%;
                    @include phone {
                        flex: 0 0 40% !important;
                        max-width: 40% !important;
                        padding-right: 5px;
                    }
                }
                .col-7 {
                    flex: 0 0 52%;
                    max-width: 52%;
                    @include phone {
                        flex: 0 0 60% !important;
                        max-width: 60% !important;
                        padding-left: 5px !important;
                    }
                }
                .col-4 {
                    @include phone {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .col-8 {
                    @include phone {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .form {
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    .row {
                        align-items: center;
                        padding-bottom: 10px;
                        .last-color {
                            letter-spacing: 0px;
                            color: #c80036;
                            font: normal normal bold 18px/27px Open Sans;
                        }
                        .fz-20 {
                            font-size: 20px;
                        }
                        .input-group {
                            border-radius: 11px;
                            height: 70px;
                            .input-group-text {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 11px 0px 0px 11px;
                                padding: 15px;
                                font-weight: bold;
                                width: 100%;
                                &:after {
                                    display: none;
                                }
                            }
                            .money-text {
                                color: #a4641a;
                            }
                            .select-icon {
                                font-weight: blod;
                                color: #a4641a;
                                font-size: 30px;
                                text-align: center;
                            }

                            .form-control {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 0px 11px 11px 0px;
                                padding: 15px;
                                font: normal normal 600 20px/22px Open Sans;
                                text-align: right;
                            }
                            .custom-rounded {
                                border-radius: 11px;
                            }
                            .btn {
                                height: 70px;
                                border-radius: 11px;
                                padding: 15px;
                                box-shadow: 0px 3px 6px #00000029;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font: normal normal bold 18px/24px Microsoft
                                    JhengHei;
                                letter-spacing: 0px;
                                @include phone {
                                    height: 60px;
                                    font-size: 16px;
                                }
                                span {
                                    position: relative;
                                    top: 2px;
                                }
                                .price {
                                    display: flex;
                                    align-items: center;
                                    font: normal normal 600 16px/22px Open Sans;
                                    strong {
                                        font: normal normal bold 29px/39px Open
                                            Sans;
                                        letter-spacing: 0px;
                                        padding-left: 20px;
                                        @include phone {
                                            font-size: 18px;
                                        }
                                    }
                                }
                            }
                            .btn-cancel {
                                background: #a2a2a2 0% 0% no-repeat padding-box;
                                justify-content: center;
                            }
                            .btn-checkout {
                                background: #4a4a4a 0% 0% no-repeat padding-box;
                            }
                        }
                    }
                }
                .pay-type {
                    height: 43px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .radio-wrap {
                    box-sizing: border-box;
                    padding-top: 10px;
                    padding-left: 0;
                }

                :deep(.authorization-input) {
                    margin-bottom: 0 !important;
                    box-shadow: none !important;
                }
            }
        }
    }
}
.input-wrap {
    position: relative;
    span {
        font-size: 12px;
        color: #f82e2e;
        position: absolute;
        left: 0;
        top: 85%;
    }
}

.violation {
    :deep(.base-select) {
        .arrow-icon {
            background: transparent linear-gradient(1deg,  #CF9064 0%, #9C5828 61%, #81471D 100%) 0 0 no-repeat;
        }
    }
}


</style>
