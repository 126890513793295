import axios from "axios";
import Swal from "sweetalert2";
import store from "../../store";
import { objToFormData } from "../functions";

const mukiConst = {
    // 會員身份
    memberType: {
        // 一般
        GENERAL: 0,
        // 業務
        SALES: 1,
    },
    // 性別
    gender: {
        FEMALE: 0,
        MALE: 1,
        EMPTY: 2,
    },
    token: {
        INVALID: -98,
    },
    csrfToken: {
        INVALID: -99,
    },
};

// 驗證
const apiBase = axios.create({
    baseURL: "/api/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/form-data",
    },
    // 請求送出前，可將要送出的參數轉換成 FormData
    transformRequest: [
        function (data, headers) {
            headers.common["userAuthToken"] = localStorage.getItem("token");
            return objToFormData(data);
        },
    ],
    // 請求返回給then或catch可接收時，可將請求的回傳值轉換為所需的格式
    transformResponse: [
        function (res) {
            apiBase.defaults.headers.common["X-Csrftoken"] =
                getCsrfTokenFromCookie();

            // if (window.mukiTokenInvalid) throw "mukiToken錯誤";

            try {
                const data = JSON.parse(res);
                // token過期
                switch (data["res_code"]) {
                    // 登入 token
                    case mukiConst.token.INVALID:
                        // window.mukiTokenInvalid = true;

                        store.dispatch("auth/logout");
                        // f7.dialog.alert(data["res_content"], "訊息提示", () => {
                        //     _direct_url("/");
                        // });

                        break;
                    // csr token
                    case mukiConst.csrfToken.INVALID:
                        // window.mukiTokenInvalid = true;

                        store.dispatch("auth/logout");
                        // f7.dialog.alert(data["res_content"], "訊息提示", () => {
                        //     _direct_url("/");
                        // });

                        break;
                    default:
                        break;
                }
                return data;
            } catch (err) {
                console.error(err);
            }

            return res;
        },
    ],
});

apiBase.defaults.headers.common["userAuthToken"] =
    localStorage.getItem("token");

const mockApiBase = axios.create({
    baseURL: "/mockWebApi/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

function getCsrfTokenFromCookie() {
    let regex = /.*csrf_cookie_name=([^;.]*).*$/;
    return document.cookie.match(regex) === null
        ? null
        : document.cookie.match(regex)[1];
}

function setApiMode(apiBase, mode) {
    if (mode === "dev") {
        apiBase.defaults.baseURL = "/api/";
    }
    return apiBase;
}

function checkLoginStatus(formInfo, mode) {
    var promise = {};
    var url = "/api_common/checkLoginStatus";

    promise = setApiMode(apiBase, mode).post(url, formInfo);

    return promise
        .then((res) => {
            const { data, status } = res;
            const resData = data["res_data"];
            return data["res_code"];
        })
        .catch((err) => {
            console.log("err", err);
            // f7.dialog.alert("伺服器錯誤，請聯繫維護人員協助處理", "訊息提示");
        });
}

// 登入
function postLogin(formInfo, mode) {
    var promise = {};
    var url = "/api_common/login";

    promise = setApiMode(apiBase, mode).post(url, formInfo);

    return promise
        .then((res) => {
            const { data, status } = res;
            const resData = data["res_data"];

            if (status === 200 && data["res_code"] === 1) {
                store.dispatch("auth/login", {
                    // 驗證
                    token: resData.token,
                    member_name: resData.member_name,
                    pic: resData.pic,
                    store_id: resData.store_id,
                    member_type: resData.member_type,
                    // 直接由輸入值取得
                    // mobile: formInfo.mobile,
                    isLogined: true,
                });
            } else {
                // f7.dialog.alert(data["res_content"], "訊息提示");
            }

            return res;
        })
        .catch((err) => {
            console.error("err", err);
            // f7.dialog.alert("伺服器錯誤，請聯繫維護人員協助處理", "訊息提示");
        });
}

// 通用
function baseGet(url, formInfo, mode) {
    if (mode === "dev") {
        return mockApiBase.get(url, formInfo);
    } else {
        return apiBase.get(url, formInfo);
    }
}

function basePost(url, formInfo, mode) {
    if (mode === "dev") {
        return mockApiBase.post(url, formInfo);
    } else {
        var promise = {};

        promise = setApiMode(apiBase, mode).post(url, formInfo);
        return promise
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("err", err);
                Swal.fire({
                    title: '訊息提示',
                    icon: 'error',
                    text: `${err.response.status}: 發生錯誤`,
                    confirmButtonText: '確定',
                })
            });
    }
}

function basePut(url, formInfo, mode) {
    if (mode === "dev") {
        return mockApiBase.put(url, formInfo);
    } else {
        return apiBase.put(url, formInfo);
    }
}

function basePatch(url, formInfo, mode) {
    if (mode === "dev") {
        return mockApiBase.patch(url, formInfo);
    } else {
        return apiBase.patch(url, formInfo);
    }
}

function baseDel(url, formInfo, mode) {
    if (mode === "dev") {
        return mockApiBase.delete(url, formInfo);
    } else {
        return apiBase.delete(url, formInfo);
    }
}



function getCustomerData(formInfo, mode) {
    var promise = {};
    var url = "/api_customer/customer_data";

    promise = setApiMode(apiBase, mode).post(url, formInfo);

    return promise
        .then((res) => {
            const { data, status } = res;
            const resData = data["res_data"];

            return resData;
        })
        .catch((err) => {
            console.log("err", err);
        });
}

export {
    postLogin,
    baseGet,
    basePost,
    basePut,
    basePatch,
    baseDel,
    checkLoginStatus,
    getCustomerData,
};
